import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import karte from '../assets/bilder/karte_bbw.jpg';

import Header from './header';

import CustomButton from '../styles/customButton.js';

function Willkommen() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="sticky">
        <Header/>
      </div>

      <div className="content">
        <h1>{t('Willkommen')}</h1>
        <h3>{t('im Besucherbergwerk Fell')}</h3>
        <br/>
        <p>{t('willkommen.Barbara-Hoffnung')}</p>
        <br/>
        <p>{t('willkommen.WeinStein')}</p>
        <br/>
        <p>{t('willkommen.Grubenwanderweg')}</p>
        <br/>
        <img src={karte} alt="Grubenwanderweg Karte" width="100%"/>
        <br/>
        <br/>
        <div style={{textAlign:"center"}}>
          <Link to="/">
            <CustomButton>{t('Zurück zur Startseite')}</CustomButton>
          </Link>
        </div>
      </div>
    </div>
    )
  }

export default Willkommen;
