import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';

import SchatzsucheMenuDialog from './menuDialog';

import CustomButton from '../../styles/customButton';
import CustomExpansionPanel from '../../styles/customExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CustomTextField from '../../styles/customTextField.js';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

function QRScanner(props) {
  const { t } = useTranslation();
  const questions = Object.keys(props.data);

  const [questionID, setQuestionID] = React.useState('0');
  const [redirectQuestion, setRedirectQuestion] = React.useState(false);
  const [redirectNotFound, setRedirectNotFound] = React.useState(false);
  const [qrCodeInput, setQrCodeInput] = React.useState("");
  const [openSnackbarMissingInput, setOpenSnackbarMissingInput] = React.useState(false);

  function renderRedirect(){
    if (redirectQuestion === true) {
      return <Redirect to={`/schatzsuche/question/${questionID}`} />
    }
    if (redirectNotFound === true) {
      return <Redirect to={`/schatzsuche/notfound`} />
    }
  }

  function handleScan(data) {
    if (data) {
      var parts = data.split('/');
      var id = parts[parts.length - 1];
      setQuestionID(id);

      if (questions.includes(id)) {
        setRedirectQuestion(true);
      }
      else {
        setRedirectNotFound(true);
      }
    }
  }

  function handleInput() {
    if (qrCodeInput !== "") {
      setQuestionID(qrCodeInput);
      if (questions.includes(qrCodeInput)){
        setRedirectQuestion(true);
      }
      else {
        setRedirectNotFound(true);
      }
    }
    else {
      setOpenSnackbarMissingInput(true);
    }
  }

  function handleError(err) {
    console.error(err)
  }

  function handleInputChange(event) {
    setQrCodeInput(event.target.value);
  }

  function handleClose() {
    setOpenSnackbarMissingInput(false);
  }

  const [expanded, setExpanded] = React.useState(false);
  const handlePanelChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return(
    <div>
      <div className="header">
        <Link to="/schatzsuche/inventar" aria-label="Zurück">
        <div className="headerLeft">
          <ArrowBackIos className="icons" style={{fontSize: 45}}/>
        </div>
        </Link>
        <div className="headerCenter">
          <h2>{t('Scanner')}</h2>
        </div>
        <div className="headerRight">
          <SchatzsucheMenuDialog />
        </div>
      </div>

      <div className="center">
        {qrScannerOrInput()}

        <Link to="/schatzsuche/inventar">
        <CustomButton>{t('Zurück zum Inventar')}</CustomButton>
        </Link>
      </div>

       <Snackbar
         key={"snackbarMissingQRNumber"}
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'center',
         }}
         open={openSnackbarMissingInput}
         autoHideDuration={5000}
         onClose={handleClose}
         ContentProps={{
           'aria-describedby': 'message-id',
         }}
         message={<span id="message-id">{t('snackbar.missingQRNumber')}</span>}
         action={[
         <IconButton
           aria-label="Close"
           color="inherit"
           onClick={handleClose}
         >
           <CloseIcon />
         </IconButton>
       ]}
       />
    </div>
  );

  function qrScannerOrInput(){
    //Show Input Field, if device is iOS and PWA is installed. (No Camera Access!)

    const isIos = () => {
      if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null){
        return true;
      }
      else return false;
    }
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    if (isIos() && isInStandaloneMode()) {
      return (
        <div>
          <h3>{t('schatzsuche.noCameraAccess')}</h3>
          <br/>
          <p>{t('schatzsuche.noCameraAccessInfo')}</p>
          <br/>
          <CustomTextField
            id="qrCodeInput"
            placeholder="QR-Code Nummer"
            onChange={handleInputChange}
            onKeyDown = {(event) => enterOnKeyDown(event)}
            fullWidth
            margin="normal"
            variant="outlined"
            style={{ marginBottom: "15px"}}
          />
          <p style={{fontSize: "13px"}}>{t('schatzsuche.qrCodeNumberInfo')}</p>
          <br/>
          <CustomButton onClick={() => handleInput()}>{t('Frage anzeigen')}</CustomButton>

          {renderRedirect()}

        </div>
      );
    }
    else {
      return(
        <div>
          <p>{t('schatzsuche.scannerInfo')}</p>
          <br/>
          <QrReader
            delay={300}
            showViewFinder={true}
            onError={handleError}
            onScan={handleScan}
            style={{ maxWidth:"550px", margin:"auto" }}
          />
          {renderRedirect()}
          <div style={{paddingTop:"20px", maxWidth:"550px", margin:"auto"}}>
            <CustomExpansionPanel square expanded={expanded === "problemPanel"} onChange={handlePanelChange('problemPanel')}>
              <ExpansionPanelSummary aria-label="Problem" id="problemPanel-header" expandIcon={<ExpandMoreIcon />}>
                <h4>{t('Es gibt ein Problem?')}</h4>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div>
                <p>{t('schatzsuche.scannerProblem')}</p>
                <p>{t('schatzsuche.scannerProblemInfo')}</p>
                <br/>
                <CustomTextField
                  id="qrCodeInput"
                  placeholder={t('QR-Code Nummer')}
                  onChange={handleInputChange}
                  onKeyDown = {(event) => enterOnKeyDown(event)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  style={{ marginBottom: "15px"}}
                />
                <br/>
                <CustomButton onClick={() => handleInput()}>{t('Frage anzeigen')}</CustomButton>
                </div>
              </ExpansionPanelDetails>
            </CustomExpansionPanel>
            <br/>
          </div>
        </div>
      );
    }

    function enterOnKeyDown(event) {
      const key = event.key;

      if ((key === "Enter")){
        handleInput();
      }
    }
  }
}

export default QRScanner;
