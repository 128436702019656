import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CustomTextField = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold',
    color: 'rgb(0,0,0)',
    margin: 2,
    maxWidth: '550px',
    height: 55,
    backgroundColor: 'rgb(222,222,222)',
    borderRadius: 5,
    fontFamily: [
      '"Poppins"',
      '"Roboto"',
      'sans-serif',
    ].join(','),
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgb(94,94,94)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(24,111,141)',
      },
    },
    input: {

    }
  },
})(TextField);

export default CustomTextField;
