import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';

import {tutorialSteps_de} from './data/tutorialSteps_de';
import {tutorialSteps_en} from './data/tutorialSteps_en';

import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import CustomButtonWhite from '../../styles/customButtonWhite.js';
import CustomIconButton from '../../styles/customIconButton.js';
import CustomTextField from '../../styles/customTextField.js';
import Dialog from '@material-ui/core/Dialog';
import CustomDialogContent from '../../styles/customDialogContent.js';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

function SchatzsucheStartDialog(props) {
  const [openStartDialog, setOpenStartDialog] = React.useState(false);

  function handleClose(){
    setOpenStartDialog(false);
  };

  React.useEffect(() => {
    if (localStorage.getItem("gameStarted") !== "true"){
      setOpenStartDialog(true);
    }
    else {
      setOpenStartDialog(false);
    }
  }, []);

  return (
    <div>
      <StartDialog open={openStartDialog} onClose={handleClose} style={{height: "100%"}}/>
    </div>
  );
}

function StartDialog(props) {
  const { onClose, ...other } = props;

  return (
    <div>
      <Dialog
        fullScreen
        onClose={onClose}
        aria-label="Bergwerk Schatzsuche Start"
        PaperProps={{
          style: {
            backgroundColor: 'rgb(24,111,141)',
          },
        }}
        {...other}>
        <CustomDialogContent>

        <div className="centerBlue" style={{height:"auto"}}>
          <div className="closeIcon">
            <Link to="/">
              <CustomIconButton aria-label="Schließen">
                <CloseIcon style={{ fontSize: 26, color: "rgb(255,255,255)" }}/>
              </CustomIconButton>
            </Link>
          </div>

          <Stepper {...props}/>

        </div>
        </CustomDialogContent>

      </Dialog>
    </div>
  )
}

function Stepper(props) {
  const { t, i18n } = useTranslation();
  let tutorialSteps;
  if (i18n.language === "en"){
    tutorialSteps = tutorialSteps_en;
  }
  else {
    tutorialSteps = tutorialSteps_de;
  }

  const maxSteps = tutorialSteps.length;
  const [activeStep, setActiveStep] = React.useState(0);
  const [username, setUsername] = React.useState("");
  const [redirectHome, setRedirectHome] = React.useState(false);
  const [openSnackbarMissingName, setOpenSnackbarMissingName] = React.useState(false);

  function startGame(){
    if (username === "") {
      setOpenSnackbarMissingName(true);
    }
    else {
      localStorage.setItem("gameStarted", true);
      localStorage.setItem("username", username);
      //Wenn Änderungen hier, dann auch in schatzsucheStart.js!
      props.onClose();
    }
  }

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    if (activeStep !== 0){
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
    else if (activeStep === 0){
      setRedirectHome(true);
    }
  }

  function createCircles() {
    const circles = []

    for (var i = 0; i < maxSteps; i++) {
      circles.push(<div id={i} className="tutorialCircle" style={((activeStep === i) ? {backgroundColor: "rgb(255,255,255)"} : null)}></div>);
    }

    return circles;
  }

  function handleTextChange(event) {
    setUsername(event.target.value);
  }

  function renderRedirect(){
    if (redirectHome === true) {
      return <Redirect to={'/'} />
    }
  }

  function handleClose(value) {
    setOpenSnackbarMissingName(false);
  }

  function enterOnKeyDown(event) {
    const key = event.key;

    if ((key === "Enter")){
      startGame();
    }
  }

  function changeLanguage(lang){
    i18n.changeLanguage(lang);
  }

  var button;

  if(activeStep !== maxSteps - 1){
    button =
      <CustomButtonWhite onClick={handleNext} aria-label="Weiter">
        {t('Weiter')} <KeyboardArrowRight />
      </CustomButtonWhite>
  }
  else{
    button =
      <CustomButtonWhite onClick={() => startGame()} aria-label="Los geht's">{t("Los geht's!")}</CustomButtonWhite>
  }

  return (
    <div>
      <div className="centerBlue">
        <SwipeableViews index={activeStep} onChangeIndex={setActiveStep}>
          <div className="tutorialDiv" key="Step1">
            {tutorialSteps[0].icon}
            <h1>{tutorialSteps[0].headline}</h1>
          </div>
          <div className="tutorialDiv" key="Step2">
            {tutorialSteps[6].icon}
            <h3>{tutorialSteps[6].headline}</h3>
            <br/>
            <p>{tutorialSteps[6].text}</p>
          </div>
          <div className="tutorialDiv" key="Step3">
            {tutorialSteps[1].icon}
            <h3>{tutorialSteps[1].headline}</h3>
            <br/>
            <p>{tutorialSteps[1].text}</p>
          </div>
          <div className="tutorialDiv" key="Step4">
            {tutorialSteps[2].icon}
            <h3>{tutorialSteps[2].headline}</h3>
            <br/>
            <p>{tutorialSteps[2].text}</p>
          </div>
          <div className="tutorialDiv" key="Step5">
            {tutorialSteps[3].icon}
            <h3>{tutorialSteps[3].headline}</h3>
            <br/>
            <p>{tutorialSteps[3].text}</p>
          </div>
          <div className="tutorialDiv" key="Step6">
            {tutorialSteps[4].icon}
            <h3>{tutorialSteps[4].headline}</h3>
            <br/>
            <p>{tutorialSteps[4].text}</p>
          </div>
          <div className="tutorialDiv" key="Step7">
            {tutorialSteps[5].icon}
            <h3>{tutorialSteps[5].headline}</h3>
            <br/>
            <p>{tutorialSteps[5].text}</p>
            <br/>
            <CustomTextField
              id="nameInput"
              placeholder="Name"
              onChange={handleTextChange}
              onKeyDown = {(event) => enterOnKeyDown(event)}
              fullWidth
              margin="normal"
              variant="outlined"
              aria-label="Name"
            />
          </div>
        </SwipeableViews>
        <div style={{ textAlign:"center", display: "block", marginTop: "12px", marginBottom: "12px" }}>
          {createCircles()}
        </div>
        {button}
        <h5 onClick={handleBack} style={{marginTop: "10px"}}>{t('Zurück')}</h5>
        {renderRedirect()}
        <br/>
        <p style={{fontSize:"30px", paddingTop:"4px", paddingBottom:"4px"}}>
          <span style={{paddingRight:"8px", peddingLeft:"8px"}}><span role="img" aria-label="deutsch" onClick={() => changeLanguage("de")}>🇩🇪</span></span>
          <span style={{paddingRight:"8px", peddingLeft:"8px"}}><span role="img" aria-label="englisch" onClick={() => changeLanguage("en")}>🇬🇧</span></span>
        </p>
      </div>

      <Snackbar
        key={"snackbarMissingName"}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbarMissingName}
        autoHideDuration={5000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{t('snackbar.missingName')}</span>}
        action={[
        <IconButton
          aria-label="Schließen"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
      />
    </div>
  );
}

export default SchatzsucheStartDialog;
