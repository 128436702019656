import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const CustomNavButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 15,
    color: 'rgb(24,111,141)',
    width: 90,
    height: 35,
    border: '2px solid',
    borderRadius: 45,
    lineHeight: 1.5,
    backgroundColor: 'rgb(255,255,255)',
    borderColor: 'rgb(255,255,255)',
    fontFamily: [
      '"Poppins"',
      '"Roboto"',
      'sans-serif',
    ].join(','),
    '&:hover': {
      color: 'rgb(24,111,141)',
      backgroundColor: 'rgb(255,255,255)',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(255,255,255)',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(94,94,94,0.5)',
    },
    '&:selected': {
      color: 'rgb(24,111,141)',
      backgroundColor: 'rgb(255,255,255)',
    },
  },
})(Button);

export default CustomNavButton;
