import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../styles/customButton.js';
import CustomNavButton from '../../styles/customNavButton.js';
import CustomDialog from '../../styles/customDialog.js';
import CustomTextField from '../../styles/customTextField.js';

import CorrectIcon from '@material-ui/icons/CheckCircle';
import WrongIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

function Station(props) {
  const { t } = useTranslation();


  return (
    <div>
      <div className="header">
        <Link to="/gww" aria-label="Zurück">
          <div className="headerLeft">
            <ArrowBackIos className="icons" style={{ fontSize: 45 }}/>
          </div>
        </Link>
        <div className="headerCenter">
        </div>
      </div>
      <div className="center">
              <div>
        <div className="center">
          <h2>{props.data.titel}</h2>
          <p style={{marginTop: "15px"}}><MyComponent/></p>
          <br/>

          </div>
      </div>
      </div>
    </div>
  )

  function getInfo() {
    return props.data.info;
  }
  function createMarkup() {
    var test = getInfo();
    return {__html: test};
  }
  function MyComponent() {
    return <div dangerouslySetInnerHTML={createMarkup()} />;
  }
}
export default Station;
