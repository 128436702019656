import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';

const CustomExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    maxWidth:'550px',
    width: '100%',
    height: 'auto',
    border: '1px solid rgba(0, 0, 0, .125)',
    lineHeight: 1.5,
    backgroundColor: 'rgb(255,255,255)',
    fontFamily: [
      '"Poppins"',
      '"Roboto"',
      'sans-serif',
    ].join(','),
    '@media (prefers-color-scheme: dark)': {
       background: 'rgb(50,50,50)',
       '&:active': {
         boxShadow: 'none',
         backgroundColor: 'rgb(40,40,40)',
       },
     }
  },
})(ExpansionPanel);

export default CustomExpansionPanel;
