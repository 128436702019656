import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();

  function changeLanguage(lang){
    i18n.changeLanguage(lang);
  }

  return (
    <div className="footer">
      <h5>Besucherbergwerk Fell</h5>
      <p style={{paddingTop:"4px", paddingBottom:"4px"}}>
        <Link to="/kontakt" aria-label="Kontakt">{t('Kontakt')} </Link>
         |
        <Link to="/datenschutz" aria-label="Datenschutz"> {t('Datenschutz')}</Link>
      </p>
      <p style={{fontSize:"25px", paddingTop:"4px", paddingBottom:"4px"}}>
        <span style={{paddingRight:"6px", peddingLeft:"6px"}}><span role="img" aria-label="deutsch" onClick={() => changeLanguage("de")}>🇩🇪</span></span>
        <span style={{paddingRight:"6px", peddingLeft:"6px"}}><span role="img" aria-label="englisch" onClick={() => changeLanguage("en")}>🇬🇧</span></span>
        <span style={{paddingRight:"6px", peddingLeft:"6px"}}><span role="img" aria-label="französisch" onClick={() => changeLanguage("fr")}>🇫🇷</span></span>
        <span style={{paddingRight:"6px", peddingLeft:"6px"}}><span role="img" aria-label="niederländisch" onClick={() => changeLanguage("nl")}>🇳🇱</span></span>
      </p>
      <br/>
    </div>
  )
}

export default Footer;
