import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MoreHoriz from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import CustomDialogContent from '../styles/customDialogContent.js';

import CustomIconButton from '../styles/customIconButton.js';

import logo from '../assets/cropped-logo_bergwerk.jpg';


function Header() {
  const [openMenuDialog, setOpenMenuDialog] = React.useState(false);

  function handleClose(value){
    setOpenMenuDialog(false);
  };

  function openMenu() {
    setOpenMenuDialog(true);
  }

  return (
    <div>
      <div className="headerHome">
        <Link to="/" aria-label="Startseite">
          <img src={logo} alt="Besucherbergwerk Fell Logo" height="90px"/>
        </Link>

        <div className="headerNav">
          <br/>
          <MoreHoriz onClick={() => openMenu()} className="icons" style={{fontSize: 45}} aria-label="Menu"/>
          <MenuDialog open={openMenuDialog} onClose={handleClose} style={{height: "100%"}}/>
        </div>
      </div>
    </div>
  );
}

function MenuDialog(props) {
  const { onClose, ...other } = props;
  const { t, i18n } = useTranslation();

  function changeLanguage(lang){
    i18n.changeLanguage(lang);
    props.onClose();
  }

  return (
    <Dialog
      fullScreen
      onClose={onClose}
      aria-label="Menu"
      PaperProps={{
        style: {
          backgroundColor: 'rgb(24,111,141)',
        },
      }}
      {...other}>
      <CustomDialogContent>
      <div className="closeIcon">
        <CustomIconButton aria-label="Schließen">
          <CloseIcon onClick={onClose} style={{ fontSize: 26, color: "rgb(255,255,255)" }}/>
        </CustomIconButton>
      </div>
      <div className="contentBlue">
        <div style={{paddingTop:"90px"}}>
          <Link to="/"><h1 onClick={onClose} style={{paddingBottom:"6px", paddingTop:"6px"}}>{t('Home')}</h1></Link>
          <Link to="/willkommen"><h1 onClick={onClose} style={{paddingBottom:"6px", paddingTop:"6px"}}>{t('Willkommen')}</h1></Link>
          <Link to="/schatzsuche"><h1 onClick={onClose} style={{paddingBottom:"6px", paddingTop:"6px"}}>{t('Schatzsuche')}</h1></Link>
          <Link to="/geschichte"><h1 onClick={onClose} style={{paddingBottom:"6px", paddingTop:"6px"}}>{t('Geschichte')}</h1></Link>
          <br/>
          <br/>
          <br/>
          <p style={{fontSize:"50px", paddingBottom:"4px"}}>
            <span style={{paddingRight:"8px", peddingLeft:"8px"}}><span role="img" aria-label="deutsch" onClick={() => changeLanguage("de")}>🇩🇪</span></span>
            <span style={{paddingRight:"8px", peddingLeft:"8px"}}><span role="img" aria-label="englisch" onClick={() => changeLanguage("en")}>🇬🇧</span></span>
            <span style={{paddingRight:"8px", peddingLeft:"8px"}}><span role="img" aria-label="französisch" onClick={() => changeLanguage("fr")}>🇫🇷</span></span>
            <span style={{paddingRight:"8px", peddingLeft:"8px"}}><span role="img" aria-label="niederländisch" onClick={() => changeLanguage("nl")}>🇳🇱</span></span>
          </p>
          <Link to="/kontakt"><h5 onClick={onClose} style={{paddingBottom:"4px", paddingTop:"4px"}}>{t('Kontakt')}</h5></Link>
          <Link to="/datenschutz"><h5 onClick={onClose} style={{paddingBottom:"4px", paddingTop:"4px"}}>{t('Datenschutz')}</h5></Link>
          <br/>
        </div>
      </div>
      </CustomDialogContent>
    </Dialog>
  );
}

export default Header;
