import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const CustomIconButtonDialog = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 45,
    color: 'rgb(255,255,255)',
    position: "absolute",
    top: 0,
    right: 12,
    width: 48,
    height: 48,
    border: 0,
    backgroundColor: 'rgba(94,94,94,.5)',
    fontFamily: [
      '"Poppins"',
      '"Roboto"',
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: 'rgba(94,94,94,.3)',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(94,94,94,.3)',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(24,111,141,.4)',
    },
  },
})(IconButton);

export default CustomIconButtonDialog;
