import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../header';

import CustomButton from '../../../styles/customButton.js';

import Eichbaum from '../../../assets/bilder/gww/eichbaum.jpg';

function GwwStation120() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="sticky">
        <Header/>
      </div>

      <div className="content">
        <h3>Grube Schürzig</h3>
        <br/>
<p>Eichbaum I war mit einer Gesamtlänge von 600m und 16 Abbaukammern das mit weitem Abstand größte Bergwerk in Fell. Eine erste Erwähnung in alten Dokumenten ist bis 1850 nachgewiesen. Die frühen Betreiber lassen sich heute jedoch nicht mehr ermitteln und auch die Geschichte der Grube ist erst ab etwa 1920 nachvollziehbar.</p>
<img src={Eichbaum} alt="Grube Eichbaum" width="100%"/>
<p>Die Grube baute auf der ergiebigsten Lagerstätte des Ortes und es entstanden im Laufe der Zeit auf diesem Konzessionsfeld insgesamt 7 Stollenbergwerke, wovon eines, der Gessinger-Stollen, unterhalb von Eichbaum und die anderen, Jakobsgrube, Alte und Neue Konzergrube, Marnach und ein weiterer Stollenbetrieb oberhalb liegen. In den fünfziger Jahren wurde vom Betreiber, den Reichsgrafen Kesselstatt, vor dem neuen Mundloch der Grube Eichbaum sogar ein Schachtbergwerk abgeteuft; es war dies der Graf-Franz-Gedächtnisschacht, der bis in die Tiefe von 60 m die Talsohle unterteufte und mit den großen Kesselstattschen Schiefergruben in Thomm durchschlägig gemacht werden sollte.</p>
<p>Aufgrund starker Aushöhlung dieses Berges liefen Eichbaum jedoch derart starke Wasser zu, daß auch das Stollensystem in der Tiefe, in Verbindung mit dem Nosserbach, absoff. Der Grube Eichbaum laufen auch heute noch die Wässer sämtlicher höher gelegenen Gruben zu; zusätzlich auch noch die Wasser der Grube Schürzig. Diese Wasser werden vollständig durch den etwa 25 m tiefen Blindschacht zwischen Eichbaum und dem Gessinger-Stollen gelöst. Der Gessinger Stollen ist daher im hintersten Teil nur mit Booten befahrbar. Aufgrund unzureichender Sicherheitsabstände zwischen den einzelnen Gruben ist es im Berg zu schweren Gebirgsbrüchen gekommen, der Zustand von Eichbaum ist daher heute nicht mehr auf ihrer gesamten Länge zu ermitteln, da viele der sporthallengroße Kammern vollständig zu Bruch gegangen sind.</p>
        <br/>
        <div style={{textAlign:"center"}}>
          <Link to="/gww">
            <CustomButton>{t('Zurück zum GWW')}</CustomButton>
          </Link>
        </div>
      </div>
    </div>
    )
  }

export default GwwStation120;
