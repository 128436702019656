import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const CustomIconButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 45,
    color: 'rgb(255,255,255)',
    // position: "fixed",
    // top: 20,
    // right: 15,
    width: 48,
    height: 48,
    border: 0,
    backgroundColor: 'rgba(255,255,255,.2)',
    fontFamily: [
      '"Poppins"',
      '"Roboto"',
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,.4)',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(24,111,141,.4)',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(24,111,141,.4)',
    },
  },
})(IconButton);

export default CustomIconButton;
