import React, { useState } from 'react';
import { Link, Switch, Route} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../header';
import qrcodeicon from '../../assets/icon/bergwerk-web-qrcode.png';
import CustomButton from '../../styles/customButton.js';

import karte from '../../assets/bilder/karte_bbw.jpg';

import NotFound from '../notfound';
import QrScanner from './qrScanner';

function GwwStart() {
  const { t } = useTranslation();

  return (
    <div> 
      <div>
        <div className="sticky">
          <Header/>
        </div>

        <div className="content">
          <h1>{t('Grubenwanderweg')}</h1>
          <h3>{t('eine kurzweilige Wanderung')}</h3>
          <br/>
          <p>Der Grubenwanderweg ist in seiner Streckenführung so konzipiert, daß man sich je nach Kondition und Interesse zwischen zwei Streckenlängen von 5km bzw. 7.5km entscheiden kann. Beide Strecken queren das Geländer des Besucherbergwerks, die längere der beiden Strecken führt Sie zusätzlich durch den Ort Fell und die Weinberge. Da die Strecke teilweise steinig ist und einen Anstieg (zur Hofgrube) aufweist, ist sie nur bedingt für Kinderwagen geeignet.</p><br/>
          <p>Die Strecke verläuft größtenteils durch den Wald, vorbei an 10 Schiefergruben, hier wird auf schiefergedeckten Informationstafeln die Geschichte der Grube, die Betriebszeit und Anhand eines Grubengrundrisses (Grubenbild) die Größe und Komplexität der jeweiligen Grube erläutert.
            Auf der Rückseite der Tafeln wird die Schiefergewinnung und Verarbeitung beschrieben!</p><br/>
          <p>
          Mit einer Taschenlampe können Sie in die vergitterten Stollen (Fledermausgitter), in denen teilweise noch die Schienen liegen, hineinleuchten und in die Stollen hineinfotografieren. Ihren Fotoapparat mit Blitz sollten Sie daher keinesfalls vergessen.
          </p><br/>
          <b>START DER WANDERUNG</b>
          <p>Parken Sie auf dem Parkplatz des Besucherbergwerkes, überqueren Sie den Bach und ab hier folgen Sie einfach dem breiten Forstweg nach rechts und der braunen Beschilderung „Grubenwanderweg Fell“. </p>
          <br/>
          <img src={karte} alt="Grubenwanderweg Karte" width="100%"/>
          <br/>
          <div style={{textAlign:"center"}}>
            <Link to="/">
              <CustomButton>{t('Zurück zur Startseite')}</CustomButton>
            </Link>
          </div>
        </div>

        <div className="fixedBoxBottom">
          <Link to="/gww/qrScanner">
            <CustomButton>
              <div style={{padding:"8px", display:"flex", alignItems:"center"}}>
              <img src={qrcodeicon} alt="QR-Code" width="40" height="40" />
                <div>
                <h4 style={{color: "white", lineHeight: "1.1em", paddingLeft:"5px"}}>QR-Code</h4>
                <p style={{fontSize:"14px", lineHeight: "1.1em", paddingLeft:"5px"}}>scannen</p>
                </div>
              </div>
            </CustomButton>
          </Link>
        </div>

      </div>
    </div>
  )
}

export default GwwStart;
