import React from 'react';
import { Link } from 'react-router-dom';

import CustomIconButton from '../../styles/customIconButton.js';
import CustomButtonWhite from '../../styles/customButtonWhite.js';

import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Error';

class ErrorBoundarySchatzsuche extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return(
        <div>
          <div className="centerBlue">
            <div className="closeIcon">
              <Link to="/">
                <CustomIconButton aria-label="Schließen">
                  <CloseIcon style={{fontSize: 26, color: "rgb(255,255,255)"}}/>
                </CustomIconButton>
              </Link>
            </div>

            <WarningIcon style={{fontSize: 120, paddingTop:"30px", paddingBottom:"10px"}}/>
            <h2>Upps. Es ist ein Fehler aufgetreten!</h2>
            <br/>
            <br/>
            <Link to="/">
              <CustomButtonWhite>Zurück zur Startseite</CustomButtonWhite>
            </Link>
            <br/>
          </div>
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundarySchatzsuche;
