import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
const resources = {
  de: {
    translation: {
      "Home": "Home",
      "Willkommen": "Willkommen",
      "im Besucherbergwerk Fell": "im Besucherbergwerk Fell",
      "Schatzsuche": "Schatzsuche",
      "Erkunde die Ausstellung interaktiv": "Erkunde die Ausstellung interaktiv",
      "Geschichte": "Geschichte",
      "Der Durchschlag im Verbindungsstollen": "Der Durchschlag im Verbindungsstollen",
      "Kontakt": "Kontakt",
      "Datenschutz": "Datenschutz",
      "Seite nicht gefunden": "Seite nicht gefunden",
      "Startseite": "Startseite",
      "Zurück zur Startseite": "Zurück zur Startseite",
      "Zurück zur Schatzsuche": "Zurück zur Schatzsuche",
      willkommen: {
        "Barbara-Hoffnung": "Das Besucherbergwerk Barbara-Hoffnung – im Nossertal zwischen Fell und Thomm – besteht aus zwei übereinander liegenden typischen Dachschiefergruben (Bergwerken) aus der Jahrhundertwende. Seit der Eröffnung am 01. Mai 1997 sind mehr als 330.000 Besucher ins Bergwerk eingefahren. Eine Führung bei uns unter Tage dauert eine gute Stunde bei einer konstanten Temperatur von 12 bis 13 Grad Celsius.",
        "WeinStein": "Für das leibliche Wohl ist ebenfalls bestens gesorgt. In unserem Shop und im Bistro „WeinStein“ erhalten Sie eine große Auswahl an Souvenirs, Erfrischungen, erlesenen Weinen und Speisen. Eine Terrasse und schiefergedeckte Pavillons laden Sie zum Verweilen ein.",
        "Grubenwanderweg": "Das Besucherbergwerk liegt direkt am Grubenwanderweg, dieser führt vorbei an 10 Schiefergruben und Steinbrüchen und erläutert die Geschichte des Bergbaus im Nossertal.",
      },
      datenschutz: {
        "Verantwortlicher": "Verantwortlicher",
        "Datenschutzerklärung": "Datenschutzerklärung",
        "erklärung": "Alle Daten werden nur lokal auf Ihrem Gerät gespeichert und verarbeitet! Alle Daten, die Sie bei der Schatzsuche angeben, bleiben bis zum Neustart der Schatzsuche auf Ihrem Gerät gespeichert. Dabei haben wir zu keinem Zeitpunkt zugriff auf diese Daten."
      },
      "Postadresse": "Postadresse",
      "Realisierung": "Realisierung",
      "installBanner1": "Installiere die Web-App auf deinem iPhone, um sie auch offline verwenden zu können: ",
      "installBanner2": "Klicke auf",
      "installBanner3": "und tippe dann auf 'Zum Home-Bildschirm'.",
      schatzsuche: {
        "inventarInfo": "Finde die gesuchten Gegenstände, indem du alle QR-Codes scannst und die entsprechende Fragen richtig beantwortest.",
        "nameÄndernInfo": "Ändere deinen Namen oder starte die Schatzsuche neu.",
        "scannerInfo": "Finde einen QR-Code und scanne ihn. Sobald ein QR-Code erkannt wird, öffnet sich automatisch die entsprechende Frage.",
        "scannerProblem": "Du kannst den QR-Code nicht scannen?",
        "scannerProblemInfo": "Kein Problem! Trage hier einfach die Nummer unterhalb des QR-Code ein.",
        "richtigInfo": "Damit hast du den nächsten Gegenstand erfolgreich gefunden.",
        "falschInfo": "Versuch es noch einmal.",
        "neustartenFrage": "Willst du die Schatzsuche wirklich neustarten?",
        "neustartenInfo": "Dabei geht dein gesamter Spielstande verloren!",
        "successInfo": "Dein Code für die Schatztruhe lautet 422. Sie steht im Bistro, da du alle Fragen beantwortet hast, darfst du sie öffnen und dir ein kleines Geschenk nehmen!",
        "questionNotFound": "Frage wurde nicht gefunden.",
        "questionNotFoundInfo": "Versuch es einfach noch einmal.",
        "error": "Upps. Es ist ein Fehler aufgetreten!",
        "errorNoItemsFound": "Keine Gegenstände gefunden.",
        "errorAnswerNotLoaded": "Die Antwortmöglichkeit konnte nicht geladen werden.",
        "questionWordsInfo": "Trage das gesuchte Wort in die Felder ein.",
        "noCameraAccess": "Leider haben wir keinen Zugriff auf deine Kamera.",
        "noCameraAccessInfo": "Bitte trage eine QR-Code Nummer ein, dadurch gelangst du zur entsprechende Frage.",
        "qrCodeNumberInfo": "Die QR-Code Nummer findest du unterhalb des QR-Codes.",
      },
      "Bergwerk Schatzsuche": "Bergwerk Schatzsuche",
      "Willkommen zurück": "Willkommen zurück",
      "Nicht du?": "Nicht du?",
      "Du bist nicht": "Du bist nicht",
      "Weiter spielen!": "Weiter spielen!",
      "Weiter": "Weiter",
      "Zurück": "Zurück",
      "Los geht's!": "Los geht's!",
      "Teilen": "Teilen",
      "Inventar": "Inventar",
      "Glückwunsch": "Glückwunsch",
      "Diplom erstellen": "Diplom erstellen",
      "Neustarten": "Neustarten",
      "Abbrechen": "Abbrechen",
      "QR-Code scannen": "QR-Code scannen",
      "QR-Code Nummer": "QR-Code Nummer",
      "Zurück zum Inventar": "Zurück zum Inventar",
      "Problem lösen": "Problem lösen",
      "Info": "Info",
      "Frage": "Frage",
      "Antworten": "Antworten",
      "Eingabe löschen": "Eingabe löschen",
      "Richtig!": "Richtig!",
      "Leider Falsch": "Leider falsch",
      "Fehlversuche": "Fehlversuche",
      "Neuer Versuch": "Neuer Versuch",
      "Scanner": "Scanner",
      "Frage anzeigen": "Frage anzeigen",
      "Es gibt ein Problem?": "Es gibt ein Problem?",
      "Name ändern": "Name ändern",
      snackbar: {
        "missingName": "Bitte trage zuerst deinen Namen ein.",
        "question": "Du hast die Frage bereits beantwortet.",
        "questionEmptyInput": "Trage zuerst deine Antwort ein.",
        "missingQRNumber": "Bitte trage eine QR-Code Nummer ein.",
        "questionNotFoundYet": "Finde und scanne zuerst den QR-Code.",
      }
    }
  },
  en: {
    translation: {
      "Home": "Home",
      "Willkommen": "Welcome",
      "im Besucherbergwerk Fell": "to the visitor mine Fell",
      "Schatzsuche": "Treasure hunt",
      "Erkunde die Ausstellung interaktiv": "Explore the exhibition interactive",
      "Geschichte": "History",
      "Der Durchschlag im Verbindungsstollen": "The breakthrough in the connecting tunnel",
      "Kontakt": "Contact",
      "Datenschutz": "Privacy",
      "Seite nicht gefunden": "Page not found",
      "Startseite": "Homepage",
      "Zurück zur Startseite": "Back to the homepage",
      "Zurück zur Schatzsuche": "Back to the treasure hunt",
      willkommen: {
        "Barbara-Hoffnung": "The Barbara-Hoffnung display mine – situated in the Nossertal Valley between Fell and Thomm – consists of two typical, superposed roof-slate mines dating back to the turn of the century. More than 300,000 visitors have passed through it since it opened on 1 May 1997. Our underground tour runs for a full hour, at a constant temperature of 12 to 13°C.",
        "WeinStein": "We’ve got creature comforts covered too, with our shop and “WeinStein” bistro offering a wide range of souvenirs, refreshments, select wines and snacks. Our terrace and slate-roofed pavilions are a great place to relax and unwind.",
        "Grubenwanderweg": "The display mine is located right on the mine trail, which passes by 10 slate mines and quarries, and explains the history of mining in the Nossertal Valley.",
      },
      datenschutz: {
        "Verantwortlicher": "Data controller",
        "Datenschutzerklärung": "Privacy policy",
        "erklärung": "All data is only stored and processed locally on your device! All data you enter during the treasure hunt will be stored on your device until you restart the treasure hunt. At no time do we have access to this data.",
      },
      "Postadresse": "Mailing address",
      "Realisierung": "Realization",
      "installBanner1": "Install this web app on your iPhone: ",
      "installBanner2": "Tap",
      "installBanner3": "and then 'Add to Home Screen'.",
      schatzsuche: {
        "inventarInfo": "Find the items by scanning all QR codes and answering the questions correctly.",
        "nameÄndernInfo": "Change your name or restart the treasure hunt.",
        "scannerInfo": "Find a QR code and scan it. As soon as a QR code is recognized, the corresponding question opens automatically.",
        "scannerProblem": "You can't scan the QR code?",
        "scannerProblemInfo": "No problem. Just enter the number below the QR-code.",
        "richtigInfo": "You have successfully found the next item.",
        "falschInfo": "Try it again.",
        "neustartenFrage": "Do you really want to restart the treasure hunt?",
        "neustartenInfo": "Your entire score will be lost!",
        "successInfo": "You have found all items! Your code for the treause chest is 422, it is placed in the bistro. Open it and take a small gift!",
        "questionNotFound": "Question not found.",
        "questionNotFoundInfo": "Just try it again.",
        "error": "Oops. An error has occurred!",
        "errorAnswerNotLoaded": "The answer option could not be loaded.",
        "errorNoItemsFound": "No items found.",
        "questionWordsInfo": "Enter the word into the fields.",
        "noCameraAccess": "Unfortunately, we don't have access to your camera.",
        "noCameraAccessInfo": "Please enter a QR code number, this will take you to the appropriate question.",
        "qrCodeNumberInfo": "The QR code number can be found below the QR code.",
      },
      "Bergwerk Schatzsuche": "Treasure hunt",
      "Willkommen zurück": "Welcome back",
      "Nicht du?": "Not you?",
      "Du bist nicht": "You're not",
      "Weiter spielen!": "Continue",
      "Weiter": "Next",
      "Zurück": "Back",
      "Los geht's!": "Get started!",
      "Teilen": "Share",
      "Inventar": "Inventory",
      "Glückwunsch": "Congratulation",
      "Diplom erstellen": "Create diploma",
      "Neustarten": "Restart",
      "Abbrechen": "Cancel",
      "QR-Code scannen": "Scan QR code",
      "QR-Code Nummer": "QR code number",
      "Zurück zum Inventar": "Back to the inventory",
      "Problem lösen": "Solve problem",
      "Info": "Info",
      "Frage": "Question",
      "Antworten": "Answer",
      "Eingabe löschen": "Delete input",
      "Richtig!": "Correct!",
      "Leider Falsch": "Nice try",
      "Fehlversuche": "Failed attempts",
      "Neuer Versuch": "New trial",
      "Scanner": "Scanner",
      "Frage anzeigen": "Show question",
      "Es gibt ein Problem?": "There's a problem?",
      "Name ändern": "Change name",
      snackbar: {
        "missingName": "Please enter your name first.",
        "question": "You've already answered the question.",
        "questionEmptyInput": "Enter your answer first.",
        "missingQRNumber": "Please enter a QR code number.",
        "questionNotFoundYet": "Find and scan the QR code first.",
      }
    }
  },
  fr: {
    translation: {
      "Home": "Accueil",
      "Willkommen": "Bienvenue",
      "im Besucherbergwerk Fell": "à la mine visiteur Fell",
      "Schatzsuche": "Chasse au trésor",
      "Erkunde die Ausstellung interaktiv": "Découvrez l'exposition interactive",
      "Geschichte": "Histoire",
      "Der Durchschlag im Verbindungsstollen": "La percée dans le tunnel de raccordement",
      "Kontakt": "Contact",
      "Datenschutz": "Données personnelles",
      "Seite nicht gefunden": "Page introuvable",
      "Startseite": "Accueil",
      "Zurück zur Startseite": "Retour à la page d'accueil",
      "Zurück zur Schatzsuche": "Retour à la chasse au trésor",
      willkommen: {
        "Barbara-Hoffnung": "La mine ouverte au public Barbara-Hoffnung – dans la vallée de la Nosser entre Fell et Thomm – se compose de deux mines d’ardoises pour les toits, disposées l’une au-dessus de l’autre, datant du tournant du siècle. Depuis son inauguration le 1er mai 1997, nous avons accueilli plus de 300.000 visiteurs dans notre mine. Une visite guidée sous terre chez nous dure une bonne heure, à une température constante comprise entre 12 et 13 degrés Celsius.",
        "WeinStein": "Chez nous, vous pourrez également vous restaurer. Dans notre boutique et dans notre snack 'WeinStein', vous trouverez une vaste sélection de souvenirs, rafraîchissements, vins fins et repas. Une terrasse et des pavillons couverts d’ardoise vous invitent à la détente.",
        "Grubenwanderweg": "La mine ouverte au public se situe directement sur le sentier de randonnée minier, qui permet de longer 10 mines d’ardoise et carrières et présente l’histoire de l’exploitation minière dans la vallée de la Nosser.",
      },
      datenschutz: {
        "Verantwortlicher": "Contrôleur de données",
        "Datenschutzerklärung": "Déclaration de confidentialité",
        "erklärung": "All data is only stored and processed locally on your device! All data you enter during the treasure hunt will be stored on your device until you restart the treasure hunt. At no time do we have access to this data."
      },
      "Postadresse": "Adresse postale",
      "Realisierung": "Réalisation",
    }
  },
  nl: {
    translation: {
      "Home": "Home",
      "Willkommen": "Welkom",
      "im Besucherbergwerk Fell": "bij de bezoekersmijn Fell",
      "Schatzsuche": "Schatzoeken",
      "Erkunde die Ausstellung interaktiv": "Verken de tentoonstelling interactief",
      "Geschichte": "Geschiedenis",
      "Der Durchschlag im Verbindungsstollen": "De pons in de verbindingslip",
      "Kontakt": "Contact",
      "Datenschutz": "Privacyverklaring",
      "Seite nicht gefunden": "Pagina niet gevonden",
      "Startseite": "Startpagina",
      "Zurück zur Startseite": "Terug naar de startpagina",
      "Zurück zur Schatzsuche": "Terug naar de schatzoeken",
      willkommen: {
        "Barbara-Hoffnung": "De bezoekersmijn Barbara-Hoffnung – in een dal, het Nossertal, tussen Fell en Thomm – bestaat uit twee boven elkaar gelegen dakleigroeven (mijnen) van rond 1900. Sinds de opening op 01 mei 1997 hebben al meer dan 300.000 bezoekers de mijn bezocht. Een ondergrondse rondleiding bij ons duurt ruim een uur bij een constante temperatuur van 12 tot 13 graden Celsius.",
        "WeinStein": "Voor een natje en een droogje is uiteraard ook gezorgd. Onze shop en snackbar 'WeinStein' biedt een ruime keuze aan souvenirs, frisdrank, geselecteerde wijnen en snacks. Op een terras en in paviljoens met leien daken is het heerlijk vertoeven.",
        "Grubenwanderweg": "De bezoekersmijn ligt langs het Mijnwandelpad. Dit wandelpad loopt langs 10 (lei)steengroeven waarbij u veel over de geschiedenis van de mijnbouw in het Nossertal leert.",
      },
      datenschutz: {
        "Verantwortlicher": "Verantwoordelijke persoon",
        "Datenschutzerklärung": "Privacyverklaring",
        "erklärung": "All data is only stored and processed locally on your device! All data you enter during the treasure hunt will be stored on your device until you restart the treasure hunt. At no time do we have access to this data."
      },
      "Postadresse": "Postadres",
      "Realisierung": "Realisatie",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "de",
    fallbackLng: "en", // use en if detected lng is not available

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

  export default i18n;
