export default {
  questions: {
    10: {
      id: 10,
      type: "multipleChoice",
      titel: "Bats",
      icon: "fledermaus",
      info: "In Germany, 24 native bat species are known to date, many of them have already been found in Fell, including the \"Bechsteinfledermaus\" and the rare \"Hufeisennase\".",
      tipp: "",
      question: "How many bat species live in Fell?",
      options: ["5", "10", "16"],
      answer: "16",
    },
    20: {
      id: 20,
      type: "multipleChoice",
      titel: "Bats",
      icon: "fledermaus",
      info: "The greatest danger comes from humans: Shortage of living space, wood preservatives, noise pollution, traffic, changes in hunting grounds and insects poisoned by pesticides. But there are also natural enemies for the bat.",
      tipp: "",
      question: "Which enemies does the bat have?",
      options: ["owl, tawny owl, falcon", "snakes and fire salamanders", "fox and wolf"],
      answer: "owl, tawny owl, falcon",
    },
    30: {
      id: 30,
      type: "multipleChoice",
      titel: "Viniculture",
      icon: "trauben",
      info: "With the age of the vineyards, the return in terms of quantity, vitality and growing power usually decreases. In contrast, the quality of the grape usually increases with the age of the vine stocks.",
      tipp: "",
      question: "How many years can a vineyard produce its grapes?",
      options: ["5 years", "10 years", "25 years"],
      answer: "25 years",
    },
    40: {
      id: 40,
      type: "multipleChoice",
      titel: "Viniculture",
      icon: "trauben",
      info: "",
      tipp: "",
      question: "What was a so-called \"Hotte\" used for in the vineyard work?",
      options: ["to transport the picked grapes", "for watering the vineyards", "to loosen up the vineyard soil"],
      answer: "to transport the picked grapes",
    },
    50: {
      id: 50,
      type: "multipleChoice",
      titel: "Mining",
      icon: "hacke",
      info: "The miners often had their own language.",
      tipp: "",
      question: "What did the miner call a \"Haspel\"?",
      options: ["a disturbance in the rock", "a cable winch", "a water outlet"],
      answer: "a cable winch",
    },
    60: {
      id: 60,
      type: "words",
      titel: "History",
      icon: "helm",
      info: "",
      tipp: "",
      question: "When was the miners' chapel founded in Fell?",
      options: [],
      answer: "1955",
    },
    70: {
      id: 70,
      type: "multipleChoice",
      titel: "Mining",
      icon: "helm",
      info: "The \"Kauekorb\" was used to store clothes during the shift of the miner.",
      tipp: "",
      question: "What's not in the \"Kauekorb\" (the locker of the miner)?",
      options: ["Shoes", "Clothing", "Tools"],
      answer: "Tools",
    },
    80: {
      id: 80,
      type: "multipleChoice",
      titel: "Transport",
      icon: "lore",
      info: "In the mine, acoustic signals were often used to communicate with each other, as they were easier to hear and recognize in the noise.",
      tipp: "",
      question: "What signal did the call bell give?",
      options: ["Lore voll!", "Achtung, Sprengung!", "Schichtende - Feierabend!"],
      answer: "Lore voll!",
    },
    90: {
      id: 90,
      type: "multipleChoice",
      titel: "Slates",
      icon: "schiefer",
      info: "The first slate tiles as a roofing can already be found with the Romans.",
      tipp: "",
      question: "How thick are the split slate tiles (\"Schuppen\")?",
      options: ["1-2 mm", "4-6 mm", "2-3 cm"],
      answer: "4-6 mm",
    },
    100: {
      id: 100,
      type: "multipleChoice",
      titel: "History",
      icon: "licht",
      info: "The findings on the history of colonization in Fell and Thomm date back to pre-Christian times.",
      tipp: "",
      question: "Who were the first settlers in the Fell Valley?",
      options: ["Roman", "Treveri", "Huguenots"],
      answer: "Treveri",
    },
    110: {
      id: 110,
      type: "multipleChoice",
      titel: "Slate",
      icon: "dach",
      info: "Because straw was everywhere and easily available, it was also used for roofing for a long time.",
      tipp: "",
      question: "Why were the roofs covered with slate instead of straw from the Middle Ages onwards?",
      options: ["Slate is nicer", "Straw was used as feed", "Slate does not burn"],
      answer: "Slate does not burn",
    },
    120: {
      id: 120,
      type: "words",
      titel: "Mariana Trench",
      icon: "licht",
      info: "In the Mariana Trench, the deepest known point in the sea, there is a water pressure of 1100kg per square centimeter. Despite these hostile living conditions, there is still life there!",
      tipp: "",
      question: "How deep is the deepest point in the sea (in meters)?",
      options: [],
      answer: "11000",
    },
    130: {
      id: 130,
      type: "multipleChoice",
      titel: "Mining",
      icon: "hacke",
      info: "In slate mining, a distinction is made between above-ground and underground mining. Above ground, i.e. at the surface, only slate can be mined for the construction of walls, not for roofing. This must be crushed underground, i.e. in mines.",
      tipp: "",
      question: "Why did the slate have to be processed immediately (mountain-moist)?",
      options: ["so it could be sold immediately", "because otherwise it dries out and can no longer be split", "because otherwise there's less dust"],
      answer: "because otherwise it dries out and can no longer be split",
    },
    140: {
      id: 140,
      type: "words",
      titel: "Wagon",
      icon: "lore",
      info: "The wagons, which stand on the forecourt of the mine, show different types of wagon and do not all come from Fell and Thomm. So there are also wagons from Luxembourg, France and Belgium. They were not used in slate mining, but were used to transport iron ore or coal.",
      tipp: "",
      question: "What number is on the second wagon to the right of the lookout bench?",
      options: [],
      answer: "3452",
    },
    150: {
      id: 150,
      type: "multipleChoice",
      titel: "Roofing slate",
      icon: "dach",
      info: "The roofing slate from the Hunsrück and Moselle regions is known for its dark, black-blue color. This color varies from mine to mine. In the past, the miners in Fell and Thomm could tell from the color and structure of the slate, which mine it came from. The colored slate on the pavilions and the exhibition comes from Portugal and Spain.",
      tipp: "",
      question: "What are the different colors of the slate roofs on the pavilions?",
      options: ["yellow/green/purple", "red/green/blue-black", "black/white/pink"],
      answer: "red/green/blue-black",
    }
  }
}
