import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './header';

import CustomButton from '../styles/customButton.js';

import WarningIcon from '@material-ui/icons/Error';

function NotFound() {
  const { t } = useTranslation();

  return(
    <div>
      <div className="sticky">
        <Header/>
      </div>
      <div className="center">
        <WarningIcon style={{fontSize: 100, color: "rgb(24,111,141)", marginTop:"10px"}}/>
        <h1 style={{fontSize: 60, paddingBottom:"5px"}}>404</h1>
        <h2>{t('Seite nicht gefunden - so ein Pech')}</h2>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Link to="/gww">
          <CustomButton>{t('Zurück zur Startseite')}</CustomButton>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
