import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';

import {tutorialSteps_de} from './data/tutorialSteps_de';
import {tutorialSteps_en} from './data/tutorialSteps_en';

import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import CustomButtonWhite from '../../styles/customButtonWhite.js';
import CustomIconButton from '../../styles/customIconButton.js';
import CustomTextField from '../../styles/customTextField.js';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

function SchatzsucheStart(props) {

  function checkGameSatus() {
    if (localStorage.getItem("gameStarted") === "true") {
      return <WelcomeBack/>
    }
    else {
      return <Stepper/>
    }
  }

  return (
    <div>
      <div className="centerBlue">
        <div className="closeIcon">
          <Link to="/">
            <CustomIconButton aria-label="Schließen">
              <CloseIcon style={{ fontSize: 26, color: "rgb(255,255,255)" }}/>
            </CustomIconButton>
          </Link>
        </div>

        {checkGameSatus()}

      </div>
    </div>
  )
}

function WelcomeBack() {
  var username = localStorage.getItem("username");
  const { t, i18n } = useTranslation();
  let tutorialSteps;
  if (i18n.language === "en"){
    tutorialSteps = tutorialSteps_en;
  }
  else {
    tutorialSteps = tutorialSteps_de;
  }

  return(
    <div>
      <div className="tutorialDiv">
        {tutorialSteps[0].icon}
        <p style={{color:"rgb(212,212,212)", paddingBottom:"5px", paddingTop:"5px"}}>{t('Bergwerk Schatzsuche')}</p>
        <h2>{t('Willkommen zurück')},</h2>
        <h2 style={{paddingBottom: "5px"}}>{username}!</h2>
        <Link to="/schatzsuche/username">
          <p style={{fontSize: 12, color:"rgb(0,0,0)"}}>{t('Nicht du?')}</p>
        </Link>
        <br/>
        <Link to="/schatzsuche/inventar">
          <CustomButtonWhite>{t('Weiter spielen!')}</CustomButtonWhite>
        </Link>
        <div style={{paddingTop: "15px"}}>
          <Link to="/">
            <h4>{t('Zurück zur Startseite')}</h4>
          </Link>
          <br/>
        </div>
      </div>
    </div>
  )
}

function Stepper() {
  const { t, i18n } = useTranslation();
  let tutorialSteps;
  if (i18n.language === "en"){
    tutorialSteps = tutorialSteps_en;
  }
  else {
    tutorialSteps = tutorialSteps_de;
  }

  const maxSteps = tutorialSteps.length - 1; //Hier wird die Warnung nicht angezeigt (nur in startDialog.js)!
  const [activeStep, setActiveStep] = React.useState(0);
  const [username, setUsername] = React.useState("");
  const [redirectHome, setRedirectHome] = React.useState(false);
  const [redirectInventar, setRedirectInventar] = React.useState(false);
  const [openSnackbarMissingName, setOpenSnackbarMissingName] = React.useState(false);

  function startGame(){
    if (username === "") {
      setOpenSnackbarMissingName(true);
    }
    else {
      localStorage.setItem("gameStarted", true);
      localStorage.setItem("username", username);
      //Wenn Änderungen hier, dann auch in startDialog.js!
      setRedirectInventar(true);
    }
  }

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    if (activeStep !== 0){
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
    else if (activeStep === 0){
      setRedirectHome(true);
    }
  }

  function createCircles() {
    const circles = []

    for (var i = 0; i < maxSteps; i++) {
      circles.push(<div id={i} key={i} className="tutorialCircle" style={((activeStep === i) ? {backgroundColor: "rgb(255,255,255)"} : null)}></div>);
    }

    return circles;
  }

  function handleTextChange(event) {
    setUsername(event.target.value);
  }

  function renderRedirect(){
    if (redirectHome === true) {
      return <Redirect to={'/'} />
    }
    if (redirectInventar === true) {
      return <Redirect to={'/schatzsuche/inventar'} />
    }
  }

  function handleClose(value) {
    setOpenSnackbarMissingName(false);
  }

  function enterOnKeyDown(event) {
    const key = event.key;

    if ((key === "Enter")){
      startGame();
    }
  }

  var button;

  if(activeStep !== maxSteps - 1){
    button =
      <CustomButtonWhite onClick={handleNext}>
        {t('Weiter')} <KeyboardArrowRight />
      </CustomButtonWhite>
  }
  else{
    button =
      <CustomButtonWhite onClick={() => startGame()}>{t("Los geht's!")}</CustomButtonWhite>
  }

  return (
    <div>
      <div className="centerBlue">
        <SwipeableViews index={activeStep} onChangeIndex={setActiveStep}>
          <div className="tutorialDiv" key="Step1">
            {tutorialSteps[0].icon}
            <h1>{tutorialSteps[0].headline}</h1>
          </div>
          <div className="tutorialDiv" key="Step2">
            {tutorialSteps[1].icon}
            <h3>{tutorialSteps[1].headline}</h3>
            <br/>
            <p>{tutorialSteps[1].text}</p>
          </div>
          <div className="tutorialDiv" key="Step3">
            {tutorialSteps[2].icon}
            <h3>{tutorialSteps[2].headline}</h3>
            <br/>
            <p>{tutorialSteps[2].text}</p>
          </div>
          <div className="tutorialDiv" key="Step4">
            {tutorialSteps[3].icon}
            <h3>{tutorialSteps[3].headline}</h3>
            <br/>
            <p>{tutorialSteps[3].text}</p>
          </div>
          <div className="tutorialDiv" key="Step5">
            {tutorialSteps[4].icon}
            <h3>{tutorialSteps[4].headline}</h3>
            <br/>
            <p>{tutorialSteps[4].text}</p>
          </div>
          <div className="tutorialDiv" key="Step6">
            {tutorialSteps[5].icon}
            <h3>{tutorialSteps[5].headline}</h3>
            <br/>
            <p>{tutorialSteps[5].text}</p>
            <br/>
            <CustomTextField
              id="usernameInput"
              placeholder="Name"
              onChange={handleTextChange}
              onKeyDown = {(event) => enterOnKeyDown(event)}
              fullWidth
              margin="normal"
              variant="outlined"
              aria-label="Name"
            />
          </div>
        </SwipeableViews>
        <div style={{ textAlign:"center", display: "block", marginTop: "12px", marginBottom: "12px" }}>
          {createCircles()}
        </div>
        {button}
        <h5 onClick={handleBack} style={{marginTop: "10px"}}>{t('Zurück')}</h5>
        {renderRedirect()}
      </div>

      <Snackbar
        key={"snackbarMissingName"}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbarMissingName}
        autoHideDuration={5000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{t('snackbar.missingName')}</span>}
        action={[
        <IconButton
          aria-label="Schließen"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
      />
    </div>
  );
}

export default SchatzsucheStart;
