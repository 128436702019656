import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ResetGameDialog from './resetGameDialog';

import CustomButtonWhite from '../../styles/customButtonWhite.js';
import CustomIconButton from '../../styles/customIconButton.js';
import CustomTextField from '../../styles/customTextField.js';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

function ChangeUsername(props) {
  const { t } = useTranslation();

  return (
    <div>
        <div className="centerBlue">
          <div  className="closeIcon">
            <Link to="/schatzsuche">
              <CustomIconButton aria-label="Schließen">
                <CloseIcon style={{ fontSize: 26, color: "rgb(255,255,255)" }}/>
              </CustomIconButton>
            </Link>
          </div>

          <Change/>

          <div style={{marginTop: "20px"}}>
          <Link to="/schatzsuche/inventar">
            <CustomButtonWhite>{t('Weiter spielen!')}</CustomButtonWhite>
          </Link>
          </div>
          <div style={{marginTop: "15px"}}>
            <Link to="/">
              <h4>{t('Zurück zur Startseite')}</h4>
            </Link>
            <br/>
          </div>
        </div>
    </div>
  )
}

function Change() {
  const { t } = useTranslation();
  var username = localStorage.getItem("username");
  const [newUsername, setNewUsername] = React.useState("");
  const [openSnackbarMissingName, setOpenSnackbarMissingName] = React.useState(false);
  const [openResetGameDialog, setOpenResetGameDialog] = React.useState(false);
  const [redirectInventar, setRedirectInventar] = React.useState(false);

  function openResetQuestion() {
    setOpenResetGameDialog(true);
  }

  function handleClose(){
    setOpenResetGameDialog(false);
    setOpenSnackbarMissingName(false);
  }

  function renderRedirect(){
    if (redirectInventar === true) {
      return <Redirect to={'/schatzsuche'} />
    }
  }

  function handleTextChange(event) {
    setNewUsername(event.target.value);
  }

  function changeUsername() {
    if (newUsername === "") {
      setOpenSnackbarMissingName(true);
    }
    else {
      localStorage.setItem("username", newUsername);
      setRedirectInventar(true);
    }
  }

  function enterOnKeyDown(event) {
    const key = event.key;

    if ((key === "Enter")){
      changeUsername();
    }
  }

  return(
    <div>
      <div style={{paddingTop: "24px"}}>
        <EditIcon style={{ fontSize: 80, paddingBottom:"10px"}}/>
        <h2 style={{paddingBottom:"10px"}}>{t('Du bist nicht')} {username}?</h2>
        <p>{t('schatzsuche.nameÄndernInfo')}</p>
        <CustomTextField
          id="newUsernameInput"
          placeholder="Name"
          onChange={handleTextChange}
          onKeyDown = {(event) => enterOnKeyDown(event)}
          fullWidth
          margin="normal"
          variant="outlined"
          style={{marginTop: "20px", marginBottom: "30px"}}
        />
        <br/>
        <CustomButtonWhite onClick={() => changeUsername()}>{t('Name ändern')}</CustomButtonWhite>
        {renderRedirect()}
        <br/>
        <CustomButtonWhite onClick={() => openResetQuestion()}>{t('Neustarten')}</CustomButtonWhite>
        <ResetGameDialog open={openResetGameDialog} onClose={handleClose} />

        <Snackbar
          key={"snackbarMissingName"}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbarMissingName}
          autoHideDuration={5000}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{t('snackbar.missingName')}</span>}
          action={[
          <IconButton
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
        />

      </div>
    </div>
  )
}

export default ChangeUsername;
