import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CustomIconButton from '../../styles/customIconButton.js';
import CustomButtonWhite from '../../styles/customButtonWhite.js';

import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Error';

function QuestionNotFound() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="centerBlue">
        <div className="closeIcon">
          <Link to="/schatzsuche/qrscanner">
            <CustomIconButton aria-label="Schließen">
              <CloseIcon style={{fontSize: 26, color: "rgb(255,255,255)"}}/>
            </CustomIconButton>
          </Link>
        </div>

        <WarningIcon style={{fontSize: 120, paddingTop:"30px", paddingBottom:"10px"}}/>
        <h2>{t('schatzsuche.questionNotFound')}</h2>
        <br/>
        <p>{t('schatzsuche.questionNotFoundInfo')}</p>
        <br/>
        <br/>
        <Link to="/schatzsuche/qrscanner">
        <CustomButtonWhite>{t('QR-Code scannen')}</CustomButtonWhite>
        </Link>
        <br/>
        <Link to="/schatzsuche/inventar">
        <CustomButtonWhite>{t('Zurück zum Inventar')}</CustomButtonWhite>
        </Link>
        <br/>
        <Link to="/">
          <CustomButtonWhite>{t('Zurück zur Startseite')}</CustomButtonWhite>
        </Link>
        <br/>
      </div>
    </div>
  );
}

export default QuestionNotFound;
