import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SchatzsucheMenuDialog from './menuDialog';
import StartDialog from './startDialog';
import SchatzsucheSuccess from './schatzsucheSuccess';

import CustomButton from '../../styles/customButton.js';

import Grid from '@material-ui/core/Grid';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import qrcodeicon from '../../assets/icon/bergwerk-web-qrcode.png';
import helm from '../../assets/icon/helm.png';
import lore from '../../assets/icon/lore.png';
import trauben from '../../assets/icon/trauben.png';
import licht from '../../assets/icon/licht.png';
import kreuz from '../../assets/icon/kreuz.png';
import eingang from '../../assets/icon/eingang.png';
import hacke from '../../assets/icon/hacke.png';
import dach from '../../assets/icon/dach.png';
import schiefer from '../../assets/icon/schiefer.png';
import fledermaus from '../../assets/icon/fledermaus.png';
import NoItemIcon from '@material-ui/icons/Settings';

function Inventar(props) {
  const { t } = useTranslation();
  var counter = localStorage.getItem('counterWrongAnswers');
  var collectedItems = JSON.parse(localStorage.getItem("collectedItems"));

  const [progressbarFilling, setProgressbarFilling] = React.useState(0);
  var percentageProgressbar = (((collectedItems === null) ? 0 : collectedItems.length) / ((props.data === null) ? 0 : Object.keys(props.data).length)) * 100;

  React.useEffect(() => {
    setProgressbarFilling(percentageProgressbar);
  }, [percentageProgressbar]);

  return (
    <div>
      {/*PopUp Fenster*/}
      <StartDialog />

      <SchatzsucheSuccess {...props}/>

      <div className="header">
        <Link to="/" aria-label="Zurück">
        <div className="headerLeft">
          <ArrowBackIos className="icons" style={{fontSize: 45}}/>
        </div>
        </Link>
        <div className="headerCenter">
          <h2>{t('Inventar')}</h2>
        </div>
        <div className="headerRight">
          <SchatzsucheMenuDialog />
        </div>
      </div>
      <div className="center">
        <p>{t('schatzsuche.inventarInfo')}</p>
        <br/>
        <h2>{(collectedItems === null) ? "0" : collectedItems.length} / {(props.data === null) ? "0" : Object.keys(props.data).length}</h2>
        <h4>{t('Fehlversuche')}: {(counter === null) ? "0" : counter}</h4>

        <div className="progressbar">
          <div className="filler" style={{width: progressbarFilling+"%"}}></div>
        </div>

        <ItemsList data={props.data}/>

      </div>

      <div className="fixedBoxBottom">
        <Link to="/schatzsuche/qrscanner">
          <CustomButton>
            <div style={{padding:"8px", display:"flex", alignItems:"center"}}>
            <img src={qrcodeicon} alt="QR-Code" width="40" height="40" />
              <div>
              <h4 style={{color: "white", lineHeight: "1.1em", paddingLeft:"5px"}}>QR-Code</h4>
              <p style={{fontSize:"14px", lineHeight: "1.1em", paddingLeft:"5px"}}>scannen</p>
              </div>
            </div>
          </CustomButton>
        </Link>
      </div>
    </div>

  )
}

function ItemsList(props) {
  const { t } = useTranslation();
  const questions = Object.values(props.data);
  var collectedItems = JSON.parse(localStorage.getItem("collectedItems"));

  function getIcon(icon){
    if (icon === "helm"){
      return <img src={helm} alt="helm" width="80" height="80"/>;
    }
    else if (icon === "fledermaus"){
      return <img src={fledermaus} alt="fledermaus" width="80" height="80"/>;
    }
    else if (icon === "lore"){
      return <img src={lore} alt="lore" width="80" height="80"/>;
    }
    else if (icon === "trauben"){
      return <img src={trauben} alt="trauben" width="80" height="80"/>;
    }
    else if (icon === "licht"){
      return <img src={licht} alt="licht" width="80" height="80"/>;
    }
    else if (icon === "kreuz"){
      return <img src={kreuz} alt="kreuz" width="80" height="80"/>;
    }
    else if (icon === "eingang"){
      return <img src={eingang} alt="eingang" width="80" height="80"/>;
    }
    else if (icon === "hacke"){
      return <img src={hacke} alt="hacke" width="80" height="80"/>;
    }
    else if (icon === "dach"){
      return <img src={dach} alt="dach" width="80" height="80"/>;
    }
    else if (icon === "schiefer"){
      return <img src={schiefer} alt="schiefer" width="80" height="80"/>;
    }
    else{
      return <NoItemIcon style={{fontSize: 65}}/>;
    }
  }

  return(
    <div>
      <Grid container justify="center" alignItems="flex-start" spacing={2}>
        {questions.length > 0 ? (
          questions.map(n => (
            <Grid key={n.id} item style={{textAlign: "center"}}>
              <div className="itemDiv">
                <Link to={`/schatzsuche/item/${n.id}`}>
                  <div className="circle" style={((collectedItems !== null) && collectedItems.includes(n.id)) ? { borderColor:"rgb(24,111,141)", background:"rgba(24,111,141,.5)" } : { borderColor:"rgb(94,94,94)", background:"rgba(222,222,222,.6)"}}>
                    {getIcon(n.icon)}
                  </div>
                  <p style={((collectedItems !== null) && collectedItems.includes(n.id)) ? { color:"rgb(24,111,141)" } : { color:"rgb(94,94,94)" }}>{n.titel}</p>
                </Link>
              </div>
            </Grid>
          ))
        ) : (
          <div>
            <br/>
            <h2>{t('schatzsuche.errorNoItemsFound')}</h2>
            <br/>
          </div>
        )}
      </Grid>
      <br/>
    </div>
  )
}

export default Inventar;
