import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const CustomButtonWhite = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    marginBottom: 5,
    marginTop: 5,
    color: 'rgb(94,94,94)',
    width: '100%',
    maxWidth: '550px',
    height: 'auto',
    minHeight: '55px',
    border: 0,
    borderRadius: 45,
    lineHeight: 1.5,
    backgroundColor: 'rgb(255,255,255)',
    fontFamily: [
      '"Poppins"',
      '"Roboto"',
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: 'rgb(255,255,255)',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(222,222,222)',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(222,222,222,0.5)',
    },
    '@media (prefers-color-scheme: dark)': {
       background: 'rgb(50,50,50)',
       color: 'rgb(24,111,141)',
       '&:hover': {
         backgroundColor: 'rgb(50,50,50)',
       },
       '&:active': {
         boxShadow: 'none',
         backgroundColor: 'rgb(40,40,40)',
       },
       '&:focus': {
         boxShadow: '0 0 0 0.2rem rgba(40,40,40,0.5)',
       },
     }
  },
})(Button);

export default CustomButtonWhite;
