import React from 'react';

import schatzsucheIcon from '../../../assets/icon/schatzsuche.png';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import QuestionIcon from '@material-ui/icons/Help';
import NewIcon from '@material-ui/icons/PhotoFilter';
import DiplomIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Face';
import WarningIcon from '@material-ui/icons/Error';

export const tutorialSteps_en = [
{
  icon: <img src={schatzsucheIcon} alt="Schatzsuche" width="190" height="190"/>,
  headline: 'Treasure hunt',
},
{
  icon: <CameraIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'Find and scan the QR codes',
  text: 'Find the QR codes in the exhibition and scan them with your smartphone.',
},
{
  icon: <QuestionIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'Answer question',
  text: 'Answer the question with as few mistakes as possible.',
},
{
  icon: <NewIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'Unlock items',
  text: 'Once you have scanned the QR code and answered the question correctly, you will receive the item in your inventory.',
},
{
  icon: <DiplomIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'Your present',
  text: 'Once you have found all the items, you will receive your code for the treasure chest, where you can take a small gift!',
},
{
  icon: <PersonIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: "What's your name?",
  text: 'Before we start, tell us your name.',
},
{
  icon: <WarningIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'Attention',
  text: "Open this page from your smartphone's pre-installed browser to make sure your score doesn't get lost!",
},
];
