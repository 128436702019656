import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ResetGameDialog from './resetGameDialog';

import MoreHoriz from '@material-ui/icons/MoreHoriz';

import CustomMenuDialog from '../../styles/customMenuDialog.js';
import CustomMenuButton from '../../styles/customMenuButton.js';

function SchatzsucheMenuDialog() {
  const [openMenuDialog, setOpenMenuDialog] = React.useState(false);

  function handleClose(){
    setOpenMenuDialog(false);
  };

  function openMenu() {
    setOpenMenuDialog(true);
  }

  return (
    <div>
      <MoreHoriz onClick={() => openMenu()} className="icons" style={{fontSize: 45}} aria-label="Menu"/>
      <MenuDialog open={openMenuDialog} onClose={handleClose} />
    </div>
  );
}

function MenuDialog(props) {
  const { t } = useTranslation();
  const { onClose, ...other } = props;
  const [openResetGameDialog, setOpenResetGameDialog] = React.useState(false);

  function handleClose(){
    setOpenResetGameDialog(false);
  };

  function openResetQuestion() {
    setOpenResetGameDialog(true);
  }

  return (
    <CustomMenuDialog onClose={onClose} aria-label="Menu" {...other}>
      <div>
        <CustomMenuButton onClick={() => openResetQuestion()} style={{color: "red"}}>{t('Neustarten')}</CustomMenuButton>
        <ResetGameDialog open={openResetGameDialog} onClose={handleClose} />
        <hr/>
        <CustomMenuButton onClick={() => share()}>{t('Teilen')} ...</CustomMenuButton>
        <hr/>
        <Link to="/schatzsuche/qrscanner">
          <CustomMenuButton onClick={onClose}>{t('QR-Code scannen')}</CustomMenuButton>
        </Link>
        <hr/>
        <Link to="/schatzsuche/inventar">
          <CustomMenuButton onClick={onClose}>{t('Inventar')}</CustomMenuButton>
        </Link>
        <hr/>
        <Link to="/">
          <CustomMenuButton onClick={onClose}>{t('Startseite')}</CustomMenuButton>
        </Link>
        <hr/>
        <CustomMenuButton onClick={onClose}>{t('Abbrechen')}</CustomMenuButton>
        </div>
    </CustomMenuDialog>
  );
}

function share() {
  if (navigator.share) {
    navigator.share({
      titel: document.titel,
      url: window.location.href,
    })
    .catch(console.error);
  }
  else {
    return;
  }
}

export default SchatzsucheMenuDialog;
