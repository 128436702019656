import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as jsPDF from 'jspdf';

import ResetGameDialog from './resetGameDialog';

import CustomIconButtonDialog from '../../styles/customIconButtonDialog.js';
import CustomButton from '../../styles/customButton.js';
import CustomDialog from '../../styles/customDialog.js';

import CloseIcon from '@material-ui/icons/Close';
import diplomIcon from '../../assets/icon/diplom.png';
import diplomIconBase64 from '../../assets/icon/diplomIconBase64.js';

function SchatzsucheSuccess(props) {
  const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);

  React.useEffect(() => {
    if ((localStorage.getItem("collectedItems") !== null) && (JSON.parse(localStorage.getItem("collectedItems")).length >= Object.keys(props.data).length)){
      setOpenSuccessDialog(true);
    }
    else {
      setOpenSuccessDialog(false);
    }
  }, [props.data]);

  function handleClose(value){
    setOpenSuccessDialog(false);
  };

  return(
    <div>
      <SuccessDialog open={openSuccessDialog} onClose={handleClose}/>
    </div>
  );
}

function SuccessDialog(props) {
  const { t } = useTranslation();
  const { onClose, ...other } = props;
  var username = localStorage.getItem("username");
  const [openResetGameDialog, setOpenResetGameDialog] = React.useState(false);

  function openResetQuestion() {
    setOpenResetGameDialog(true);
  }

  function handleClose(value){
    setOpenResetGameDialog(false);
  };

  return (
    <CustomDialog onClose={onClose} aria-labelledby="title" {...other}>
      <div className="centerDialog">
        <CustomIconButtonDialog onClick={onClose} aria-label="Schließen">
          <CloseIcon style={{ fontSize: 26, color: "rgb(255,255,255)" }}/>
        </CustomIconButtonDialog>
        <img src={diplomIcon} alt="diplom" width="115" height="115"/>
        <h2 id="titel">{t('Glückwunsch')}, {username}!</h2>
        <p style={{marginTop:"5px", marginBottom:"15px"}}>{t('schatzsuche.successInfo')}</p>

        <br/>
        <CustomButton onClick={() => openResetQuestion()}>{t('Neustarten')}</CustomButton>
        <ResetGameDialog open={openResetGameDialog} onClose={handleClose} />
        <br/>
        <Link to="/">
          <CustomButton>{t('Startseite')}</CustomButton>
        </Link>
        <Link to="/schatzsuche/username">
          <p style={{fontSize: 14, paddingTop:"10px"}}>{t('Du bist nicht')} {username}?</p>
        </Link>
      </div>
    </CustomDialog>
  );
}

function createPDF() {
  var username = localStorage.getItem("username");
  var counterWrongAnswers = localStorage.getItem('counterWrongAnswers');
  var pdf = new jsPDF("p", "mm", "a4");

  pdf.addImage(diplomIconBase64, 'PNG', 68, 13, 74, 74);

  pdf.setTextColor(24,111,141);
  pdf.setFont("helvetica");
  pdf.setFontType("bold");
  pdf.setFontSize(45);
  pdf.text("Bergwerks-Diplom", 105, 104, "center");
  pdf.setFontSize(42);
  pdf.text(username, 105, 140, 'center');

  pdf.setTextColor(94,94,94);
  pdf.setFontType("normal");
  pdf.setFontSize(20);
  if (counterWrongAnswers === null) {
    pdf.text('hat ohne einen einzigen Fehler', 105, 155, 'center');
  }
  else if (counterWrongAnswers === "1") {
    pdf.text('hat mit nur einem Fehlversuch', 105, 155, 'center');
  }
  else {
    pdf.text('hat mit nur ' + counterWrongAnswers + ' Fehlversuchen', 105, 155, 'center');
  }
  pdf.text('die Schatzsuche im Besucherbergwerk Fell', 105, 165, 'center');
  pdf.text('erfolgreich gemeistert.', 105, 175, 'center');

  pdf.setFont("times");
  pdf.setFontType("italic");
  pdf.setFontSize(28);
  pdf.text('Der Grubenaufseher', 105, 230, 'center');
  pdf.setFont("helvetica");
  pdf.setFontType("normal");
  pdf.setFontSize(15);
  let datum = new Date().toLocaleDateString()
  pdf.text("Fell, den " + datum, 105, 241, 'center');

  pdf.setTextColor(24,111,141);
  pdf.setFontSize(13);
  pdf.setFontType("bold");
  pdf.text("Besucherbergwerk Fell", 105, 274, 'center');
  pdf.setFontType("normal");
  pdf.text("Auf den Schiefergruben 3, 54341 Fell", 105, 280, 'center');
  pdf.text("www.bergwerk-fell.de", 105, 286, 'center');

  window.open(pdf.output('bloburl'), '_blank');
  //pdf.output('dataurlnewwindow');
}

export default SchatzsucheSuccess;
