import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';

const CustomDialogContent = withStyles({
  root: {
    padding: '0',
  },
})(DialogContent);

export default CustomDialogContent;
