import React from 'react';

import schatzsucheIcon from '../../../assets/icon/schatzsuche.png';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import QuestionIcon from '@material-ui/icons/Help';
import NewIcon from '@material-ui/icons/PhotoFilter';
import DiplomIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Face';
import WarningIcon from '@material-ui/icons/Error';

export const tutorialSteps_de = [
{
  icon: <img src={schatzsucheIcon} alt="Schatzsuche" width="190" height="190"/>,
  headline: 'Bergwerk Schatzsuche',
  text: 'mit kleiner Überraschung',
},
{
  icon: <CameraIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'QR-Code finden und scannen',
  text: 'Finde die QR-Codes in der Ausstellung (12) und dem Freigelände (3) und scanne sie mit deinem Smartphone.',
},
{
  icon: <QuestionIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'Frage beantworten',
  text: 'Beantworte die entsprechende Frage, mit so wenigen Fehlversuchen wie möglich.',
},
{
  icon: <NewIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'Gegenstände freischalten',
  text: 'Hast du den QR-Code gescannt und die Frage dazu richtig beantwortet, erhälst du den Gegenstand in dein Inventar.',
},
{
  icon: <DiplomIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'Deine Belohnung',
  text: 'Hast du alle Gegenstände gefunden, erhälst du deinen Zahlencode für die Schatztruhe, aus der du dir eine kleine Überraschung nehmen darfst!',
},
{
  icon: <PersonIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'Wie heißt du?',
  text: 'Bevor es losgeht ,verrate uns noch deinen Namen.',
},
{
  icon: <WarningIcon style={{ fontSize: 100, paddingBottom:"10px"}}/>,
  headline: 'Achtung',
  text: 'Öffne diese Seite über den vorinstallierten Browser deines Smartphones, damit dein Spielstand nicht verloren geht!',
},
];
