import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './i18n.js';

import './styles/base.css';

import * as serviceWorker from "./serviceWorker";

import NotFound from './components/notfound';
import Footer from './components/footer';
import Home from './components/home';
import Willkommen from './components/willkommen';
import SchatzsucheMain from './components/schatzsuche/schatzsucheMain';
//import Geschichte from './components/geschichte';
import Kontakt from './components/kontakt';
import Datenschutz from './components/datenschutz';
import GwwMain from './components/gww/gwwMain';
import QrScanner from './components/gww/qrScanner';
import ScrollToTop from './components/scrollToTop';

ReactDOM.render((
  <Router basename="/wp-content/uploads/app">
    <ScrollToTop>
      <App/>
    </ScrollToTop>
  </Router>
), document.getElementById("root"));

serviceWorker.register();

function App() {
  return (
      <div className="main">
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/schatzsuche" component={SchatzsucheMain}/>
          <Route path="/willkommen" component={Willkommen}/>

          <Route path="/gww" component={GwwMain}/>
          <Route path="/kontakt" component={Kontakt}/>
          <Route path="/datenschutz" component={Datenschutz}/>
          <Route component={Home} />
        </Switch>

        <Footer/>
      </div>
  );
}
