import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

const CustomMenuDialog = withStyles({
  root: {

  },
  paper: {
    minWidth: '90vw',
    borderRadius: '8px',
    paddingTop: '8px',
    paddingBottom: '8px',
    '@media (prefers-color-scheme: dark)': {
       background: 'rgb(50,50,50)',
     }
  }
})(Dialog);

export default CustomMenuDialog;
