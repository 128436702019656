import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './header';

import WillkommenBackground from '../assets/bilder/DSC_0020_HDR2-1920x1200.jpg';
import schatzsucheIcon from '../assets/icon/schatzsuche.png';
import GeschichteBackground from '../assets/bilder/002.jpg';

import iOSActionIcon from '../assets/icon/iOSActionIcon.png';


function Home() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="sticky">
        <Header/>
      </div>

      <div className="homeBackground">
        <Link to="/willkommen" aria-label="Willkommen">
          <div className="homeBox" style={{backgroundImage: "url(" + WillkommenBackground + ")", backgroundSize: "cover", backgroundPosition: "center center"}}>
            <div className="homeBoxBottom">
              <h2>{t('Willkommen')}</h2>
              <p>{t('im Besucherbergwerk Fell')}</p>
            </div>
          </div>
        </Link>

        <Link to="/schatzsuche" aria-label="Schatzsuche">
          <div className="homeBox" style={{backgroundColor: "rgb(24,111,141)"}}>
            <div style={{display:"flex", justifyContent:"center", paddingTop:"7px"}}>
              <img src={schatzsucheIcon} alt="Schatzsuche" width="150" height="150"/>
            </div>
            <div className="homeBoxBottom">
              <h2>{t('Schatzsuche')}</h2>
              <p>{t('Erkunde die Ausstellung interaktiv')}</p>
            </div>
          </div>
        </Link>

        <Link to="/gww" aria-label="Bildergalerie">
          <div className="homeBox" style={{backgroundImage: "url(" + GeschichteBackground + ")", backgroundSize: "cover"}}>
            <div className="homeBoxBottom">
              <h2>{t('Grubenwanderweg')}</h2>
              <p>{t('eine kurzweilige Wanderung')}</p>
            </div>
          </div>
        </Link>

        {installPWABanneriOS()}

      </div>
    </div>
  );

  function installPWABanneriOS(){
    //Show PWA Install Banner, if device is iOS and PWA is not installed
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    if (isIos() && !isInStandaloneMode()) {
      return(
        <div>
          <div style={{background:"rgb(24,111,141)", padding:"5%",  WebkitTextSizeAdjust:"none"}}>
            <p style={{fontSize:"16px"}}>{t('installBanner1')} <br/> {t('installBanner2')} <img src={iOSActionIcon} alt="share" width="14px"/> {t('installBanner3')}</p>
          </div>
          <br/>
        </div>
      );
    }
  }
}

export default Home;
