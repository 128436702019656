import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SchatzsucheMenuDialog from './menuDialog';
import StartDialog from './startDialog';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CustomNavButton from '../../styles/customNavButton.js';
import CustomButton from '../../styles/customButton.js';

function Item(props) {
  const { t } = useTranslation();
  const [openSnackbarRouteFrage, setOpenSnackbarRouteFrage] = React.useState(false);
  const [redirectQuestion, setRedirectQuestion] = React.useState(false);

  var collectedItems = JSON.parse(localStorage.getItem("collectedItems"));

  function handleClose() {
    setOpenSnackbarRouteFrage(false);
  }

  function checkAnsweredQuestion() {
    if ((collectedItems !== null) && collectedItems.includes(props.data.id)) {
      setRedirectQuestion(true);
    }
    else {
      setOpenSnackbarRouteFrage(true);
    }
  }

  function renderRedirect(){
    if (redirectQuestion === true) {
      return <Redirect to={`/schatzsuche/question/${props.data.id}`} />
    }
  }

  return (
    <div>
      {/*PopUp Fenster*/}
      <StartDialog />

      <div className="header">
      <Link to="/schatzsuche/inventar" aria-label="Zurück">
        <div className="headerLeft">
          <ArrowBackIos className="icons" style={{fontSize: 45}}/>
        </div>
      </Link>
      <div className="headerCenter">
        <ButtonGroup aria-label="Navigation Button">
          <CustomNavButton>
            <Link to={`/schatzsuche/item/${props.data.id}`} style={{color: "rgb(24,111,141)"}}>{t('Info')}</Link>
          </CustomNavButton>
          <CustomNavButton onClick={() => checkAnsweredQuestion()} style={{backgroundColor: "rgb(24,111,141)"}}>
            <span style={{color: "rgb(255,255,255)"}}>{t('Frage')}</span>
          </CustomNavButton>
        </ButtonGroup>
      </div>
      <div className="headerRight">
        <SchatzsucheMenuDialog />
      </div>
      </div>
      <div className="center">
        <h2>{props.data.titel}</h2>
        {(props.data.info !== "" ?
        <p style={{marginTop: "15px"}}>{props.data.info}</p>
        : null )}
        <br/>
        <h3 style={{marginBottom: "5px"}}>{props.data.tipp !== "" ? "Tipp:" : null}</h3>
        <p>{props.data.tipp}</p>
        <br/>
        <br/>
        <br/>
        <div style={{marginTop: "15px"}}>
          <CustomButton style={((collectedItems !== null) && collectedItems.includes(props.data.id)) ? null : {backgroundColor:"rgb(222,222,222)", color:"rgb(94,94,94)"}} onClick={() => checkAnsweredQuestion()}>{t('Frage anzeigen')}</CustomButton>
          {renderRedirect()}
          <br/>
          <Link to="/schatzsuche/qrscanner">
            <CustomButton>{t('QR-Code scannen')}</CustomButton>
          </Link>
          <br/>
          <Link to="/schatzsuche/inventar">
            <CustomButton>{t('Zurück zum Inventar')}</CustomButton>
          </Link>
        </div>

        <Snackbar
          key={"snackbarRouteFrage"}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbarRouteFrage}
          autoHideDuration={5000}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{t('snackbar.questionNotFoundYet')}</span>}
          action={[
          <IconButton
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
        />

      </div>
    </div>
    )
  }

export default Item;
