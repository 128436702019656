import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const CustomButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    color: 'rgb(255,255,255)',
    marginBottom: 5,
    marginTop: 5,
    width: '100%',
    maxWidth: '550px',
    height: 'auto',
    minHeight: '55px',
    border: 0,
    borderRadius: 45,
    lineHeight: 1.5,
    backgroundColor: 'rgb(24,111,141)',
    fontFamily: [
      '"Poppins"',
      '"Roboto"',
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: 'rgb(24,111,141)',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(24,111,141)',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(24,111,141,0.5)',
    },
  },
})(Button);

export default CustomButton;
