import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ErrorBoundaryGww from './errorBoundaryGww';

import GwwStart from './gwwStart';
import Station from './station';

import GwwStation10 from './stationen/10'
import GwwStation20 from './stationen/20'
import GwwStation30 from './stationen/30'
import GwwStation40 from './stationen/40'
import GwwStation50 from './stationen/50'
import GwwStation60 from './stationen/60'
import GwwStation70 from './stationen/70'
import GwwStation80 from './stationen/80'
import GwwStation90 from './stationen/90'
import GwwStation100 from './stationen/100'
import GwwStation110 from './stationen/110'
import GwwStation120 from './stationen/120'
import GwwStation130 from './stationen/130'
import GwwStation140 from './stationen/140'
import GwwStation150 from './stationen/150'

import QRScanner from './qrScanner';
import NotFound from '../notfound';

import DataDE from './data/station_de';
import DataEN from './data/station_en';


function GwwMain() {
  const { i18n } = useTranslation();
  const questions_de = DataDE;
  const questions_en = DataEN;

  const [data, setData] = useState(questions_de);

  React.useEffect(() => {
    if (i18n.language === "de"){
      setData(questions_de);
    }
    else {
      setData(questions_en);
    }
  }, [i18n.language, questions_de, questions_en]);

  return (
    <div>
      <ErrorBoundaryGww>
        <Switch>
          <Route exact path="/gww" component={GwwStart}/>
          <Route path="/gww/station/10" component={GwwStation10}/>
          <Route path="/gww/station/20" component={GwwStation20}/>
          <Route path="/gww/station/30" component={GwwStation30}/>
          <Route path="/gww/station/40" component={GwwStation40}/>
          <Route path="/gww/station/50" component={GwwStation50}/>
          <Route path="/gww/station/60" component={GwwStation60}/>
          <Route path="/gww/station/70" component={GwwStation70}/>
          <Route path="/gww/station/80" component={GwwStation80}/>
          <Route path="/gww/station/90" component={GwwStation90}/>
          <Route path="/gww/station/100" component={GwwStation100}/>
          <Route path="/gww/station/110" component={GwwStation110}/>
          <Route path="/gww/station/120" component={GwwStation120}/>
          <Route path="/gww/station/130" component={GwwStation130}/>
          <Route path="/gww/station/140" component={GwwStation140}/>
          <Route path="/gww/station/150" component={GwwStation150}/>

          <Route path="/gww/station/:id" render={(props) => <Station {...props} data={data.stations[props.match.params.id]}/>}/>
          <Route path="/gww/qrscanner" render={(props) => <QRScanner {...props} data={data.stations}/>}/>
          <Route component={NotFound} />
        </Switch>
      </ErrorBoundaryGww>
    </div>
  )
}

export default GwwMain;
