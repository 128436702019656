import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './header';

import CustomButton from '../styles/customButton.js';

function Datenschutz() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="sticky">
        <Header/>
      </div>

      <div className="content">
        <h2>{t('Datenschutz')}</h2>
        <br/>
        <h3>{t('datenschutz.Verantwortlicher')}</h3>
        <br/>
        <p>Ortsgemeinde Fell</p>
        <p>Ortsbürgermeister Alfons Rodens</p>
        <p>Kirchstraße 43</p>
        <p>D-54341 Fell</p>
        <p>Telefon: 06502-99323</p>
        <p>Telefax: 06502-99324</p>
        <p>Mail: <a href="mailto:buergermeister@fell-mosel.de">buergermeister@fell-mosel.de</a></p>
        <br/>
        <br/>
        <h3>{t('datenschutz.Datenschutzerklärung')}</h3>
        <br/>
        <p>{t('datenschutz.erklärung')}</p>
        <br/>
        <br/>
        <br/>

        <div style={{textAlign:"center"}}>
          <Link to="/">
            <CustomButton>{t('Zurück zur Startseite')}</CustomButton>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Datenschutz;
