import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SchatzsucheMenuDialog from './menuDialog';
import StartDialog from './startDialog';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

import CustomButton from '../../styles/customButton.js';
import CustomNavButton from '../../styles/customNavButton.js';
import CustomDialog from '../../styles/customDialog.js';
import CustomTextField from '../../styles/customTextField.js';

import CorrectIcon from '@material-ui/icons/CheckCircle';
import WrongIcon from '@material-ui/icons/Cancel';

function Question(props) {
  const { t } = useTranslation();
  const [openSnackbarFrage, setOpenSnackbarFrage] = React.useState(false);
  const [openCorrectDialog, setOpenCorrectDialog] = React.useState(false);
  const [openWrongDialog, setOpenWrongDialog] = React.useState(false);
  var collectedItemsArray = JSON.parse(localStorage.getItem("collectedItems"));

  function handleClose(value) {
    setOpenCorrectDialog(false);
    setOpenWrongDialog(false);
    setOpenSnackbarFrage(false);
  }

  function getQuestionType() {
    if (props.data.type === "multipleChoice"){
      return <MultipleChoice {...props} disableAnswers={disableAnswers} evaluateAnswer={evaluateAnswer}/>
    }
    else if (props.data.type === "words"){
      return <Words {...props} disableAnswers={disableAnswers} evaluateAnswer={evaluateAnswer}/>
    }
    else {
      return(
        <div>
          <h3>{t('schatzsuche.error')}</h3>
          <p>{t('schatzsuche.errorAnswerNotLoaded')}</p>
          <CustomButton onClick={() => collectedItems()}>{t('Problem lösen')}</CustomButton>
          <br/>
        </div>
      );
    }
  }

  function evaluateAnswer(value) {
    if ((collectedItemsArray !== null) && collectedItemsArray.includes(props.data.id)){
      setOpenSnackbarFrage(true);
    }
    else if (value === props.data.answer)
    {
      setOpenCorrectDialog(true);
      collectedItems();
    }
    else {
      setOpenWrongDialog(true);
      counterWrongAnswers();
    }
  }

  function disableAnswers(value){
    var collectedItems = JSON.parse(localStorage.getItem("collectedItems"));

    if (props.data.type === "multipleChoice"){
      if ((collectedItems !== null) && collectedItems.includes(props.data.id) && (value === props.data.answer)){
        return { backgroundColor: "rgba(24,111,141,0.5)", color: "rgb(94,94,94)" };
      }
      else if ((collectedItems !== null) && collectedItems.includes(props.data.id)) {
        return { backgroundColor: "rgba(200,200,200,0.7)", color: "rgb(94,94,94)" };
      }
    }
    else if (props.data.type === "words" && (collectedItems !== null) && collectedItems.includes(props.data.id)){
      return { backgroundColor: "rgb(222,222,222)", color: "rgb(94,94,94)" };
    }
  }

  function counterWrongAnswers(){
    var counter = localStorage.getItem("counterWrongAnswers");
    if (counter === null) {
      counter = 1;
    }
    else {
      counter++;
    }
    localStorage.setItem("counterWrongAnswers", counter);
  }

  function collectedItems(){
    var collectedItems = JSON.parse(localStorage.getItem("collectedItems"));
    var addCollectedItems = [];
    if (collectedItems === null){
      addCollectedItems = [props.data.id];
    }
    else{
      addCollectedItems = collectedItems;

      if (!addCollectedItems.includes(props.data.id)){
        addCollectedItems.push(props.data.id);
      }
    }
    localStorage.setItem("collectedItems", JSON.stringify(addCollectedItems));
  }

  return (
    <div>
      {/*PopUp Fenster*/}
      <StartDialog />

      <div className="header">
      <Link to="/schatzsuche/inventar" aria-label="Zurück">
        <div className="headerLeft">
          <ArrowBackIos className="icons" style={{ fontSize: 45 }}/>
        </div>
      </Link>
      <div className="headerCenter">
        <ButtonGroup aria-label="Navigation Button">
          <CustomNavButton style={{backgroundColor: "rgb(24,111,141)"}}>
            <Link to={`/schatzsuche/item/${props.data.id}`} style={{color: "rgb(255,255,255)"}}>{t('Info')}</Link>
          </CustomNavButton>
          <CustomNavButton>
            <Link to={`/schatzsuche/question/${props.data.id}`} style={{color: "rgb(24,111,141)"}}>{t('Frage')}</Link>
          </CustomNavButton>
        </ButtonGroup>
      </div>
      <div className="headerRight">
        <SchatzsucheMenuDialog />
      </div>
      </div>
      <div className="center">
        <h2>{props.data.titel}</h2>
        {(props.data.info !== "" ?
        <p style={{marginTop: "15px"}}>{props.data.info}</p>
        : null )}
        <br/>
        <h3>{props.data.question}</h3>
        <br/>
        {getQuestionType()}

        <Snackbar
          key={"snackbarFrage"}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbarFrage}
          autoHideDuration={5000}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{t('snackbar.question')}</span>}
          action={[
          <IconButton
            aria-label="Schließen"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
        />

        <CorrectDialog open={openCorrectDialog} />
        <WrongDialog open={openWrongDialog} onClose={handleClose} />

        <div style={{marginTop: "15px"}}>
          <Link to="/schatzsuche/inventar" aria-labelledby="back">
            <h4 id="back">{t('Zurück zum Inventar')}</h4>
          </Link>
        </div>
      </div>
    </div>
    )
  }

  function MultipleChoice(props) {
    return(
      <div>
        <CustomButton style={props.disableAnswers(props.data.options[0])} onClick={() => props.evaluateAnswer(props.data.options[0])}>{props.data.options[0]}</CustomButton>
        <br/>
        <CustomButton style={props.disableAnswers(props.data.options[1])} onClick={() => props.evaluateAnswer(props.data.options[1])}>{props.data.options[1]}</CustomButton>
        <br/>
        <CustomButton style={props.disableAnswers(props.data.options[2])} onClick={() => props.evaluateAnswer(props.data.options[2])}>{props.data.options[2]}</CustomButton>
      </div>
    )
  }

  function Words(props) {
    const { t } = useTranslation();
    var answer = Object.values(props.data.answer);
    var answerInput = "";
    const [openSnackbarEmptyInput, setOpenSnackbarEmptyInput] = React.useState(false);

    function getAnswerInput(){
      if (document.getElementById(answer.length-1).value === ""){
        setOpenSnackbarEmptyInput(true);
      }
      else {
        answerInput = "";
        for (var i = 0; i < answer.length; i++) {
          answerInput += document.getElementsByTagName("input")[i].value;
        }
        props.evaluateAnswer(answerInput.toUpperCase());
      }
    }

    function setAnswerInput(){
      var collectedItems = JSON.parse(localStorage.getItem("collectedItems"));

      if ((collectedItems !== null) && collectedItems.includes(props.data.id)) {
        return (
          <form id="form">
          {answer.length > 0 ? (
            answer.map((n, i) => (
              <CustomTextField
                id = {i.toString()}
                key = {i}
                value = {n}
                variant = "outlined"
                style = {{ width: 40 }}
                inputProps = {{
                  maxLength: 1,
                }}
                disabled = {true}
                onClick = {() => props.evaluateAnswer()}
              />
            ))
          ) : (
            <p>{t('schatzsuche.error')}</p>
          )}
          </form>
        )
      }
      else {
        return (
          <form id="form">
          {answer.length > 0 ? (
            answer.map((n, i) => (
              <CustomTextField
                id = {i.toString()}
                key = {i}
                variant = "outlined"
                style = {{ width: 40 }}
                inputProps = {{
                  maxLength: 1,
                }}
                onInput = {() => focusInput(i)}
                onKeyDown = {(event) => focusInputOnKeyDown(event, i)}
                onClick = {() => resetInput()}
              />
            ))
          ) : (
            <p>{t('schatzsuche.error')}</p>
          )}
          </form>
        )
      }
    }

    function focusInputOnKeyDown(event, i) {
      const key = event.key;

      if ((key === "Backspace" || key === "Delete") && (i !== 0)) {
        if (document.getElementById(i).value.length !== 0){
          document.getElementById(i).value = "";
          document.getElementById(i).focus();
        }
        else {
          document.getElementById(i).value = "";
          document.getElementById(i-1).focus();
        }
      }
      if (key === "Enter" && i !== 0){
        getAnswerInput()
      }
    }

    function focusInput(i) {
      if (document.getElementById(i).value.length === 0){
        document.getElementById(i).focus();
      }
      else if (answer.length > i+1){
        document.getElementById(i+1).focus();
      }
    }

    function resetInput() {
      var collectedItemsArray = JSON.parse(localStorage.getItem("collectedItems"));

      if ((collectedItemsArray !== null) && collectedItemsArray.includes(props.data.id)){
        props.evaluateAnswer();
      }
      else {
        document.getElementById("form").reset();
        document.getElementById(0).focus();
      }
    }

    return(
      <div>
        {setAnswerInput()}
        <p style={{fontSize: "14px", marginTop: "8px"}}>{t('schatzsuche.questionWordsInfo')}</p>
        <br/>
        <CustomButton onClick={() => getAnswerInput()}>{t('Antworten')}</CustomButton>
        <br/>
        <CustomButton onClick={() => resetInput()}>{t('Eingabe löschen')}</CustomButton>

        <Snackbar
          key={"snackbarEmptyInput"}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbarEmptyInput}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbarEmptyInput(false)}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{t('snackbar.questionEmptyInput')}</span>}
          action={[
          <IconButton
            aria-label="Close"
            color="inherit"
            onClick={() => setOpenSnackbarEmptyInput(false)}
          >
            <CloseIcon />
          </IconButton>
        ]}
        />

      </div>
    )
  }

  function CorrectDialog(props) {
    const { t } = useTranslation();
    const { onClose, ...other } = props;

    return (
      <CustomDialog onClose={onClose} aria-labelledby="title" {...other}>
        <div className="centerDialog">
          <CorrectIcon style={{ fontSize: 80, color: "rgb(67,160,71)"}} />
          <h2 style={{color: "rgb(67,160,71)"}} id="titel">{t('Richtig!')}</h2>
          <p style={{marginTop:"15px", marginBottom:"25px"}}>{t('schatzsuche.richtigInfo')}</p>
          <Link to="/schatzsuche/inventar">
          <CustomButton>{t('Zurück zum Inventar')}</CustomButton>
          </Link>
          <br/>
        </div>
      </CustomDialog>
    );
  }

  function WrongDialog(props) {
    const { t } = useTranslation();
    const { onClose, ...other } = props;
    var counter = localStorage.getItem('counterWrongAnswers');

    return (
      <CustomDialog onClose={onClose} aria-labelledby="title" {...other}>
        <div className="centerDialog">
          <WrongIcon style={{ fontSize: 80, color: "rgb(229,57,53)"}} />
          <h2 style={{color: "rgb(229,57,53)"}} id="titel">{t('Leider falsch')}</h2>
          <p style={{marginTop:"15px", marginBottom:"20px"}}>{t('schatzsuche.falschInfo')}</p>
          <h4 style={{color: "rgb(229,57,53)", marginBottom:"25px"}}>{t('Fehlversuche')}: {(counter === null) ? "0" : counter}</h4>
          <CustomButton onClick={onClose}>{t('Neuer Versuch')}</CustomButton>
          <br/>
          <Link to="/schatzsuche/inventar">
          <CustomButton>{t('Zurück zum Inventar')}</CustomButton>
          </Link>
          <br/>
        </div>
      </CustomDialog>
    );
  }

export default Question;
