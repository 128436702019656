import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import facebook from '../assets/facebook_logo.png';
import MailIcon from '@material-ui/icons/AlternateEmail';

import Header from './header';

import CustomButton from '../styles/customButton.js';

function Kontakt() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="sticky">
        <Header/>
      </div>

      <div className="content">
        <h2>{t('Kontakt')}</h2>
        <br/>
        <h3 style={{paddingBottom:"6px"}}>Besucherbergwerk Fell</h3>
        <p>Auf den Schiefergruben 3</p>
        <p>D-54341 Fell</p>
        <p>Tel.: <a href="tel:+49(0)6502988588">+49(0)6502–988588</a> oder <a href="tel:+49(0)6502994019">+49(0)6502–994019</a></p>
        <p>Handy: <a href="tel:+49(0)15157560813">+49(0)151–57560813</a></p>
        <p>e-mail: <a href="mailto:info@bergwerk-fell.de">info@bergwerk-fell.de</a></p>
        <p>Internet: <a target="_blank" rel="noopener noreferrer" href="http://www.bergwerk-fell.de">www.bergwerk-fell.de</a></p>
        <br/>
        <h3 style={{paddingBottom:"6px"}}>{t('Postadresse')}</h3>
        <p>Besucherbergwerk Fell</p>
        <p>Kirchstraße 43</p>
        <p>D-54341 Fell</p>
        <br/>
        <h3 style={{paddingBottom:"6px"}}>{t('Realisierung')}</h3>
        <p>Andreas Koster</p>
        <p>Hochschule Trier</p>
        <br/>
        <br/>
        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BesucherbergwerkFell/" aria-label="Facebook-Seite">
          <img src={facebook} alt="Facebook" width="80px" style={{marginRight:"10px"}}/>
        </a>
        <a href="mailto:info@bergwerk-fell.de" aria-label="E-Mail senden">
          <MailIcon style={{fontSize: 80}}/>
        </a>
        <br/>
        <br/>
        <br/>

        <div style={{textAlign:"center"}}>
          <Link to="/">
            <CustomButton>{t('Zurück zur Startseite')}</CustomButton>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Kontakt;
