import React from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../styles/customButton.js';
import CustomDialog from '../../styles/customDialog.js';
import CustomIconButtonDialog from '../../styles/customIconButtonDialog.js';

import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Error';

function ResetGameDialog(props) {
  const { t } = useTranslation();
  const { onClose, ...other } = props;
  const [redirect, setRedirect] = React.useState(false);

  function renderRedirect(){
    if (redirect === true) {
      return <Redirect to='/schatzsuche' />
    }
  }

  function resetGame(){
    localStorage.removeItem("counterWrongAnswers");
    localStorage.removeItem("collectedItems");
    localStorage.removeItem("gameStarted");
    localStorage.removeItem("username");

    setRedirect(true);
  }

  return(
    <CustomDialog onClose={onClose} {...other}>
      <div className="centerDialog">
        <CustomIconButtonDialog onClick={onClose} aria-label="Schließen">
          <CloseIcon style={{ fontSize: 26, color: "rgb(255,255,255)" }}/>
        </CustomIconButtonDialog>
        <WarningIcon style={{ fontSize: 80, color: "rgb(239,83,80)"}} />

        <h2 style={{color: "rgb(239,83,80)"}}>{t('schatzsuche.neustartenFrage')}</h2>
        <p style={{marginTop:"15px", marginBottom:"15px"}}>{t('schatzsuche.neustartenInfo')}</p>

        {renderRedirect()}

        <CustomButton style={{color:"white", background:"rgb(239,83,80)"}} onClick={resetGame}>{t('Neustarten')}</CustomButton>
        <br/>
        <CustomButton onClick={onClose}>{t('Abbrechen')}</CustomButton>
      </div>
    </CustomDialog>
  )
}

export default ResetGameDialog;
