export default {
  questions: {
    10: {
      id: 10,
      type: "multipleChoice",
      titel: "Fledermäuse",
      icon: "fledermaus",
      info: "In Deutschland sind bisher 24 einheimische Fledermausarten bekannt, viele davon konnten bereits in Fell nachgewiesen werden, darunter die Bechsteinfledermaus und auch die seltene Hufeisennase.",
      tipp: "",
      question: "Wie viele Fledermausarten gibt es in Fell?",
      options: ["5", "10", "16"],
      answer: "16",
    },
    20: {
      id: 20,
      type: "multipleChoice",
      titel: "Fledermäuse",
      icon: "fledermaus",
      info: "Die größe Gefahr geht vom Menschen aus: Verknappung des Wohnraumangebotes, Holzschutzmittel, Lärmbelästigung, Verkehr, Veränderung des Jagdgebietes und durch Pflanzenschutzmittel vergiftete Insekten. Es gibt aber auch natürliche Feinde für die Fledermaus.",
      tipp: "",
      question: "Welche Feinde hat die Fledermaus?",
      options: ["Eule, Waldkauz, Falke", "Schlangen und Feuersalamander", "Fuchs und Wolf"],
      answer: "Eule, Waldkauz, Falke",
    },
    30: {
      id: 30,
      type: "multipleChoice",
      titel: "Weinbau",
      icon: "trauben",
      info: "Mit dem Alter der Weinberge nimmt die Ertragsleistung bezüglich Menge, Vitalität und Wuchskraft in der Regel ab. Im Gegensatz hierzu steigert sich meist die Qualität der Traube mit zunehmendem Alter der Weinstöcke.",
      tipp: "",
      question: "Wie viele Jahre kann ein Weinberg seinen Ertrag, also Trauben, erbringen?",
      options: ["5 Jahre", "10 Jahre", "25 Jahre"],
      answer: "25 Jahre",
    },
    40: {
      id: 40,
      type: "multipleChoice",
      titel: "Weinbau",
      icon: "trauben",
      info: "Zuber, Hotten oder Bütten gehörten zum Herbstgeschirr des Winzers. Die Lesehelfer hatten schwer zu tragen, in der heißen Sonne den Hang hinauf, hinunter und wieder hinauf.",
      tipp: "",
      question: "Wozu diente eine sogenannte \"Hotte\" bei der Weinbergsarbeit?",
      options: ["zum Transport der gelesenen Trauben", "zum Bewässern der Weinberge", "zum Auflockern des Weinbergbodens"],
      answer: "zum Transport der gelesenen Trauben",
    },
    50: {
      id: 50,
      type: "multipleChoice",
      titel: "Bergbau",
      icon: "hacke",
      info: "Die Bergleute hatten oft eine eigene Sprache. So ist der \"Hut\" der Helm des Bergmannes, die Sprengung wird \"Schiessen\" genannt und das \"Geleucht\" ist die Grubenlampe.",
      tipp: "",
      question: "Was nannte der Bergmann eine \"Haspel\"?",
      options: ["eine Störung im Gestein", "eine Seilwinde", "einen Wasserablauf"],
      answer: "eine Seilwinde",
    },
    60: {
      id: 60,
      type: "words",
      titel: "Geschichte",
      icon: "helm",
      info: "Die Bergmannskapellen in Fell und Thomm legen bis heute grossen Wert auf Musik, Traditiion und Brauchtumspflege. Am Barbaratag oder bei Bergmannsparaden wird die Barbarastatue mitgeführt, tragen Vereinsmitglieder Karbidlampen und Steigerstock, die an die Bergbauvergangenheit erinnern. Dazu erklingt der Traditionsmarsch \"Glück Auf\".",
      tipp: "",
      question: "Wann wurde die Bergmannskapelle in Fell gegründet?",
      options: [],
      answer: "1955",
    },
    70: {
      id: 70,
      type: "multipleChoice",
      titel: "Bergbau",
      icon: "helm",
      info: "Der Kauekorb diente zum aufbewahren der sauberen Alltagskleidung während der \"Schicht\", der Arbeitszeit des Bergmannes. Der Korb wurde meist an einer Kette befestigt und konnte hochgezogen werden bis unter die Decke. So war die Kleidung vor Verschmutzung und Diebstahl geschützt.",
      tipp: "",
      question: "Was befindet sich nicht im \"Kauekorb\", der im Museum ausgestellt ist?",
      options: ["Schuhe", "Kleidung", "Werkzeug"],
      answer: "Werkzeug",
    },
    80: {
      id: 80,
      type: "multipleChoice",
      titel: "Transport",
      icon: "lore",
      info: "Im Bergwerk hat man sich oft mit akustischen Signalen verständigt, da diese im Lärm besser zu hören und zu erkennen waren. So wurde z.B. vor Sprengungen oder dem bedienen eines Förderkorbs gewarnt.",
      tipp: "",
      question: "Welches Signal gab die Rufglocke?",
      options: ["Lore voll!", "Achtung, Sprengung!", "Schichtende - Feierabend!"],
      answer: "Lore voll!",
    },
    90: {
      id: 90,
      type: "multipleChoice",
      titel: "Schieferplatten",
      icon: "schiefer",
      info: "Die ersten Schieferplatten als Dacheindeckung sind schon bei den Römern zu finden. Diese Platten wurde meist in Tagebau gewonnen und hatten wenig gemein mit den eleganten, dünnen und gleichmäßigen Dachschieferplatten, die wir heute kennen. Außerdem waren sie noch sehr dick, was ein beträchtliches Gewicht der Dacheindeckung verursachte.",
      tipp: "",
      question: "Wie dick sind die gespaltenen Schieferplatten (\"Schuppen\")?",
      options: ["1-2 mm", "4-6 mm", "2-3 cm"],
      answer: "4-6 mm",
    },
    100: {
      id: 100,
      type: "multipleChoice",
      titel: "Geschichte",
      icon: "licht",
      info: "Die Funde zur Besiedelungsgeschichte in Fell und Thomm stammen aus vorchristlicher Zeit. Die damaligen Bewohner wurden dann ca. 70 vor Christus in einer Schlacht beim nahegelegenen Riol geschlagen und von der Römern unterworfen.",
      tipp: "",
      question: "Wer waren die ersten Siedler im Feller Tal?",
      options: ["Römer", "Treverer", "Hugenotten"],
      answer: "Treverer",
    },
    110: {
      id: 110,
      type: "multipleChoice",
      titel: "Schiefer",
      icon: "dach",
      info: "Weil Stroh überall und leicht verfügbar war, wurde es lange Zeit auch zur Dacheindeckung genutzt. So waren bis ins Mittelalter viele Dächer in Trier strohgedeckt, was bei einer engen Bebauung in einer Stadt sehr gefährlich werden kann.",
      tipp: "",
      question: "Warum deckte man ab dem Mittelalter die Dächer mit Schiefer statt mit Stroh?",
      options: ["Schiefer ist schöner", "Stroh wurde verfüttert", "Schiefer brennt nicht"],
      answer: "Schiefer brennt nicht",
    },
    120: {
      id: 120,
      type: "words",
      titel: "Marianengraben",
      icon: "licht",
      info: "Im Marianengraben, der tiefsten bekannten Stelle im Meer, herrscht ein Wasserdruck von 1100kg pro Quadratzentimeter. Trotz dieser feindlichen Lebensumstände gibt es selbst dort noch Leben!",
      tipp: "",
      question: "Wie tief ist die tiefste Stelle im Meer (in Metern)?",
      options: [],
      answer: "11000",
    },
    130: {
      id: 130,
      type: "multipleChoice",
      titel: "Bergbau",
      icon: "hacke",
      info: "Beim Schieferbergbau unterscheidet man ober- und unterirdischen Abbau. Oberirdisch, also an der Tagesoberfläche, können nur Schiefersteine für den Mauerbau gewonnen werden, kein Dachschiefer. Dieser muss unterirdisch, also in Bergwerken, gebrochen werden.",
      tipp: "",
      question: "Warum musste der Schiefer sofort (bergfeucht) verarbeitet werden?",
      options: ["damit er direkt verkauft werden konnte", "weil er sonst austrocknet und nicht mehr gespalten werden kann", "weil er dann weniger staubt"],
      answer: "weil er sonst austrocknet und nicht mehr gespalten werden kann",
    },
    140: {
      id: 140,
      type: "words",
      titel: "Loren",
      icon: "lore",
      info: "Die Loren, die auf dem Vorplatz des Bergwerkes stehen, zeigen verschiedene Bauformen und stammen nicht alle aus Fell und Thomm. So sind hier auch Loren aus Luxemburg, Frankreich und Belgien ausgestellt. Diese wurden nicht im Schieferbergbau eingesetzt, sondern in ihnen wurde Eisenerz oder Kohle transportiert.",
      tipp: "",
      question: "Welche Zahl steht auf der zweiten Lore rechts neben der Aussichtsbank (Blickrichtung Tal)?",
      options: [],
      answer: "3367",
    },
    150: {
      id: 150,
      type: "multipleChoice",
      titel: "Dachschiefer",
      icon: "dach",
      info: "Der in Deutschland gewonnene Dachschiefer aus der Hunsrück- und Moselregion ist bekannt für seine dunkle, schwarz-blaue Farbe. Diese ist von Grube zu Grube unterschiedlich. Früher konnten die Bergleute in Fell und Thomm an der Farbe und Struktur des Schiefers erkennen, aus welchem Bergwerk er stammt. Der farbige Schiefer auf den Pavilllons und der Ausstellung stammt aus Portugal und Spanien.",
      tipp: "",
      question: "Welche unterschiedlichen Farben haben die Schieferdächer der Pavilllons?",
      options: ["gelb/grün/lila", "rot/grün/blauschwarz", "schwarz/weiss/rosa"],
      answer: "rot/grün/blauschwarz",
    }
  }
}
