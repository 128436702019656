import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ErrorBoundarySchatzsuche from './errorBoundarySchatzsuche';

import QuestionNotFound from './questionNotFound';
import SchatzsucheStart from './schatzsucheStart';
import Inventar from './inventar';
import Item from './item';
import Question from './question';
import QRScanner from './qrScanner';
import Username from './changeUsername';
import NotFound from '../notfound';

import DataDE from './data/questions_de';
import DataEN from './data/questions_en';


function SchatzsucheMain() {
  const { i18n } = useTranslation();
  const questions_de = DataDE;
  const questions_en = DataEN;

  const [data, setData] = useState(questions_de);

  React.useEffect(() => {
    if (i18n.language === "de"){
      setData(questions_de);
    }
    else {
      setData(questions_en);
    }
  }, [i18n.language, questions_de, questions_en]);

  return (
    <div>
      <ErrorBoundarySchatzsuche>
        <Switch>
          <Route exact path="/schatzsuche" component={SchatzsucheStart}/>
          <Route path="/schatzsuche/inventar" render={(props) => <Inventar {...props} data={data.questions}/>}/>
          <Route path="/schatzsuche/item/:id" render={(props) => <Item {...props} data={data.questions[props.match.params.id]}/>}/>
          <Route path="/schatzsuche/question/:id" render={(props) => <Question {...props} data={data.questions[props.match.params.id]}/>}/>
          <Route path="/schatzsuche/qrscanner" render={(props) => <QRScanner {...props} data={data.questions}/>}/>
          <Route path="/schatzsuche/username" render={(props) => <Username {...props}/>}/>
          <Route path="/schatzsuche/notfound" render={(props) => <QuestionNotFound {...props}/>}/>
          <Route component={NotFound} />
        </Switch>
      </ErrorBoundarySchatzsuche>
    </div>
  )
}

export default SchatzsucheMain;
